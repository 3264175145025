.footer{
  background: linear-gradient(80deg, #000, #333);
  // border-bottom: solid 100px #000;
  padding: 80px 0;
  @include mq(ml,max){
    padding: 40px 0;
  }
  .page--magazine &,
  .page-sale &,
  .page-property &{
    padding-bottom: 180px;
    @include mq(ml,max){
      padding-bottom: 160px;
    }
  }
  .page-property-detail &{
    padding-bottom: 160px;
    @include mq(ml,max){
      padding-bottom: 140px;
    }
  }
  &__inner{
    display: flex;
    justify-content: space-between;
    color: #fff;
    @include mq(ml,max){
      flex-direction: column-reverse;
    }
    a {
      color: #fff;
      &:hover{
        color: #fff;
      }
    }
    path{
      fill: #fff;
    }
  }
  &__logo{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-shrink: 0;

    svg{
      margin: 0 0 40px;
      @include mq(ml,max){
        display: none;
      }
    }
    a {
      display: inline-block;
      margin: 0 0 20px;
      font-size: 14px;
      &:hover{
        color: #fff;
        opacity: 0.6;
      }
      @include mq(ml,max){
        font-size: 10px;
      }
    }
  }
  &__copyright{
    max-width: 420px;
    font-size: 12px;
    letter-spacing: 0.2em;
    @include mq(ml,max){
      margin: 30px 0 0;
      max-width: unset;
      font-size: 10px;
    }
  }
  &__contents{
    max-width: 900px;
    width: 100%;
    margin-left: 200px;
    @include mq(ml,max){
      margin-left: 0;
    }
  }
  &__nav{
    display: flex;
    margin: 0 0 50px 0;
    @include mq(ml,max){
      flex-wrap: wrap;
      margin: 0 -5px;
    }
  }
  &__links{
    margin: 0 30px 0 0;
    width: 100%;
    @include mq(ml,max){
      width: calc(50% - 10px);
      margin: 0 5px 30px;
      &:nth-child(2){
        order: -1;
      }
    }
    &:last-child{
      @include mq(ml,min){
        margin: 0;
      }
    }
    li{
      margin: 1em 0 0;
      font-size: 14px;
      text-indent: -1em;
      padding-left: 1em;
      @include mq(ml,max){
        line-height: 1.2;
      }
      &:first-child{
        margin: 0;
        @include mq(ml,max){
          font-weight: bold;
        }
      }
    }
  }
  &__terms{
    display: flex;
    margin: 0;
    @include mq(ml,max){
      flex-wrap: wrap;
      padding: 20px 0;
      border-top: solid 1px #FFFFFF;
      border-bottom: solid 1px #FFFFFF;
    }
    li {
      font-size: 14px;
      margin: 0 60px 0 0;
      @include mq(ml,max){
        width: calc(50% - 5px);
        line-height: 1.2;
        margin: 10px 0;
        &:nth-child(odd){
          margin: 10px 10px 10px 0;
        }
      }
      @include mq(ml,min){
        &:last-child{
          margin: 0;
        }
      }
    }
  }
  &-pageTop{
    display: none;
    cursor: pointer;
    position: fixed;
    right: 10px;
    bottom: 20px;
    height: 60px;
    width: 60px;
    border-radius: 50px;
    background-color: #fff;
    z-index: 10;
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%229.002%22%20height%3D%2215.744%22%20viewBox%3D%220%200%209.002%2015.744%22%3E%20%3Cpath%20id%3D%22Icon_ionic-ios-arrow-forward%22%20data-name%3D%22Icon%20ionic-ios-arrow-forward%22%20d%3D%22M13.786%2C12%2C7.828%2C6.047a1.12%2C1.12%2C0%2C0%2C1%2C0-1.589%2C1.135%2C1.135%2C0%2C0%2C1%2C1.594%2C0l6.75%2C6.745a1.123%2C1.123%2C0%2C0%2C1%2C.033%2C1.552L9.427%2C19.547a1.125%2C1.125%2C0%2C1%2C1-1.594-1.589Z%22%20transform%3D%22translate(16.5%2019.875)%20rotate(180)%22%20fill%3D%22%23333%22%2F%3E%3C%2Fsvg%3E');
    transform: rotate(90deg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 10px;
    box-shadow: 0 4px 20px rgba(0,0,0,0.14);
    .page--magazine &,
    .page-sale &,
    .page-property &{
      bottom: 110px;
      @include mq(ml,max){
        bottom: 100px;
      }
    }
    .page-property-detail &{
      bottom: 90px;
      @include mq(ml,max){
        bottom: 90px;
      }
    }
  }
}
.footer__arrow {
  position: relative;
  padding: 0 0 0 14px;
}
.footer__arrow::before {
  content: "";
  position: absolute;
  top: 50%;   /* 縦軸をセンタリングする */ 
  left: 0;
  transform: translateY(-50%);   /* 縦軸をセンタリングする */  
  border: 4px solid transparent;
  border-left: 5px solid #fff;   /* 好みで色を変えてください */  
}