.lp-feature{
  // @include mq(md,max){
  //   background-color: #F4F4F4;
  // }
  .heading{
    @include mq(md,max){
      font-weight: bold;
      font-size: 21px;
    }
  }
  &__heading{
    font-size: 24px;
    letter-spacing: 0.3em;
    display: flex;
    align-items: center;
    margin: 0 0 25px 0;
    @include mq(md,max){
      font-size: 20px;
      // margin: 0 0 40px 0;
      letter-spacing: 0.1em;
    }
    &:before{
      content: "";
      width: 60px;
      height: 1px;
      background-color: #161C2E;
      margin: 0 20px 0 0;
      @include mq(md,max){
        width: 30px;
        margin: 0 10px 0 0;
      }
    }
  }
  &-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &-item{
    display: flex;
    width: calc(50% - 15px);
    align-items: center;
    @include mq(md,max){
      align-items: flex-start;
      width: 100%;
      margin: 30px 0 0;
      &:first-child{
        margin: 0;
      }
    }
    &__img{
      width: 160px;
      margin: 0 30px 0 0;
      flex-shrink: 0;
      border-radius: 50%;
      background-color: #ECEFF5;
      @include mq(md,max){
      // background-color: #fff;
        width: 50px;
        margin: 0 10px 0 0;
      }
    }
    &__heading{
      font-size: 24px;
      font-weight: bold;
      line-height: 1.5;
      @include mq(md,max){
        font-size: 16px;
      }
    }
    &__text{
      font-size: 14px;
      margin: 10px 0 0;
      @include mq(md,max){
        margin: 5px 0 0;
        font-size: 13px;
        line-height: 1.5;
      }
    }
  }
  &__register {
    // position: fixed;
    // left: 0;
    // bottom: 0;
    height: 100px;
    padding: 0 40px;
    background-color: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: solid 1px#CACACA;
    @include mq(md,max){
      padding: 0 5px;
      display: block;
      text-align: center;
    }
    .btn{
      font-size: 14px;
      flex-direction: column;
      height: 80px;
      padding: 0 80px;
      font-weight: initial;
      @include mq(md,max){
        height: 50px;
        font-size: 12px;
        min-width: 280px;
        padding: 0 45px;
        // .layout__mv:not(.nav-scrolled) &{
        //   background: #fff;
        //   color: $c-primary;
        //   box-shadow: 0 0 0 1px $c-primary;
        // }
      }
      span{
        font-size: 20px;
        font-weight: bold;
        @include mq(md,max){
          margin: 3px 0 0;
          font-size: 17px;
        }
      }
    }
  }
  &__register-text {
    font-size: 16px;
    margin: 0 auto;
    color: #333;
    width: 100%;
    text-align: end;
    margin-right: 30px;
    @include mq(md,max) {
      font-size: 12px;
      padding: 5px 0;
      text-align: center;
    }
  }
  &__bottom-banner {
    position: fixed;
    transform: translateY(100%);
    height: 80px;
    transition: .3s;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    filter: drop-shadow(0px 10px 20px #ccd5e5);
    z-index: 10;
    @include mq(md,max) {
      flex-direction: column;
      height: 95px;
    }
    .btn{
      font-size: 14px;
      flex-direction: column;
      height: 60px;
      padding: 0 80px;
      font-weight: initial;
      @include mq(md,max){
        height: 50px;
        font-size: 12px;
        min-width: 280px;
        padding: 0 45px;
      }
      span{
        font-size: 20px;
        font-weight: bold;
        @include mq(md,max){
          margin: 3px 0 0;
          font-size: 17px;
        }
      }
    }
  }
  
}
.active__bottom-banner {
  transform: translate(0);
}
