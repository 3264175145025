
.modal{
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  &__wrap{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 20px;
    height: 100%;
  }
  &__inner{
    width: 66.6666666667%;
    margin: 0 auto;
    max-width: 800px;
    @include mq(md, max){
      margin: 40px auto;
      width: 100%;
    }
    &--calendar{
      max-width: 800px;
    }
  }
  &__contents{
    background-color: #fff;
    padding: 40px;
    position: relative;
    z-index: 1;
  }
  &__header{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    background-color: #fff;
  }
  &__heading{
    margin: 0 auto 0 30px;
    color: #333333;
    font-weight: bold;
  }
  &__close{
    position:fixed;
    top: 10px;
    right: 10px;
    width: 60px;
    height: 60px;
    padding: 10px;
    margin: 0 7px 0 auto;
    background: white;
    fill: #333333;
    border-radius: 30px;
    &:hover{
      opacity: 0.6;
    }
  }
  &__bg{
    width: 101vw;
    height: 101vh;
    background-color: rgba(0,0,0,0.7);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 10;
    display: none;
  }
}
