.company{
  max-width: 800px;
  margin: 0 auto;
  &-table{
    dl{
      display: flex;
      padding: 20px 0;
      border-top: solid 1px #ddd;
      &:last-child{
        border-bottom: solid 1px #ddd;
      }
      @include mq(md,max){
        display: block;
        font-size: 14px;
        padding: 15px 0;
      }
    }
    dt{
      width: 20%;
      padding: 0 20px 0 0;
      @include mq(md,max){
        width: 100%;
        padding: 0;
        margin: 0 0 10px 0
      }
    }
    dd{
      width: 80%;
      @include mq(md,max){
        width: 100%;
      }
    }
  }
  &-notes{
    text-align: right;
    font-size: 10px;
    color: #555;
  }
}
