.nav{
  // padding: 0 40px;
  width: 100%;
  position:fixed;
  top:0;
  left:0;
  right:0;
  background: rgba(255,255,255,1);
  z-index: 10;
  // border-bottom:1px solid $c-light;
  transition: 0.4s;
  .layout__mv:not(.nav-scrolled,.menu-open) &, &--transparent{
    background: rgba(255,255,255,0);
    border-bottom:none;
  }
  .nav-scrolled &{
    transition: 0.4s;
  }
  @include mq(ml,max){
    padding: 0 0 0 20px;
    .width-wrap{
      width: 100%;
    }
  }
  &__inner{
    height: 90px;
    display: flex;
    align-items: center;
    @include mq(ml,max){
      height: 60px;
    }
    @include mq(ml,min){
      .layout__mv:not(.nav-scrolled,.menu-open) &{
        height: auto;
        margin-top: 35px;
      }
    }
  }
  &__brand{
    margin: 0 80px 0 0;
    @include mq(ml,max){
      margin: 0 auto 0 0;
    }
    svg{
      width: 214px;
      height: auto;
      display: block;
      @include mq(ml,max){
        width: 140px;
      }
    }
    path,rect{
      .layout__mv:not(.nav-scrolled,.menu-open) &, .nav--transparent &{
        fill: #fff;
      }
    }
  }
  &__links{
    display: flex;
    align-items: center;
    @include mq(ml,max){
      display:none;
      .menu-open &{
        display: block;
        position: fixed;
        left: 0;
        top: 60px;
        width: 100%;
        height: calc(100% - 60px);
        background: #fff;
        overflow: scroll;
        padding-bottom: 100px;
      }
    }
  }
  &__link{
    font-size: 14px;
    font-weight: bold;
    color: #343D55;
    line-height: 70px;
    .layout__mv:not(.nav-scrolled,.menu-open) &{
      color: #fff;
      font-weight: normal;
    }
    @include mq(ml,max){
      font-size: 16px;
      margin: 32px 0 0;
      line-height: 24px;
    }
    &--icon{
      @include mq(ml,max){
        display:block;
      }
    }
    &__dropdown {
      cursor: pointer;
    }
    &--haslower{
      position: relative;
      cursor: pointer;
      margin: 0 10px;
      @include mq(ml,max) {
        margin: 32px 0 0;
      }
      &:after{
        .nav-is-active &{
          content: "";
          left: 0;
          top: calc(100% - 15px);
          width: 100%;
          height: 3px;
          background-color: #E60E14;
          position: absolute;
          @include mq (ml,max){
            background-color: transparent;
          }
          .layout__mv:not(.nav-scrolled,.menu-open) &{
            background-color: #fff;
          }
        }
      }
    }
    &--magazine{
      position: relative;
      cursor: pointer;
      &:after{
        content: "";
        left: 0;
        top: calc(100% + 10px);
        width: 100%;
        height: 3px;
        background-color: #E60E14;
        position: absolute;
        @include mq(ml,max){
          background-color: transparent;
        }
      }
    }
    &--info {
      @include mq(ml,max) {
        margin-top: 32px;
      }
    }
    &__solar:hover {
      position: relative;
      cursor: pointer;
      opacity: 1;
      &:after{
      content: "";
      left: 0;
      bottom: -12px;
      width: 100%;
      height: 3px;
      background-color: #E60E14;
      position: absolute;
      @include mq(ml,max){
        background-color: transparent;
      }
        .layout__mv:not(.nav-scrolled,.menu-open) & {
          background-color: #fff;
          color: #343D55;
        }
      }
    }    
    &__solar a:hover{
        .layout__mv:not(.nav-scrolled,.menu-open) & {
          color: #343D55;
        }
      }
  }
  &__category{
    position: relative;
    margin-left: 40px;
    @include mq(ml,max){
      margin: 0;
      padding: 0 20px;
    }
  }
  &__lower{
    box-shadow: 0 5px 20px rgba(0,0,0,0.14);
    position: absolute;
    left: -20px;
    top: 100%;
    background-color: #FFFFFF;
    padding: 0 20px;
    display: none;
    @include mq(ml,max){
      display:block!important;
      position: initial;
      padding: 0;
      box-shadow: 0 0 0;
      margin: 8px 0 0;
    }
    .nav-is-active &{
      display: block;
      
    }
    & li{
      border-bottom: solid 1px #CACACA;
      margin: 0;
      @include mq(ml,max){
        &:first-child{
          border-top: solid 1px #CACACA;
        }
      }
      @include mq(ml,min){
        // border-top: solid 1px #CACACA;
        &:last-child{
          border-bottom: 0px;
        }
      }
    }
    & a {
      min-width: 230px;
      display: block;
      color: #07153E;
      font-size: 14px;
      padding: 20px 0;
      white-space: nowrap;
      line-height: 1;
      margin: 0;
      @include mq(ml,max){
        line-height: 1.5em;
        font-size: 16px;
        padding: 16px 0;
      }
    }
  }
  &__terms{
    @include mq(ml,min){
      display: none;
    }
    padding: 0 20px;
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    margin:1em 0 0;
    &>*{
      width: 50%;
      margin: 0.5em 0;
      color: #000;
    }
  }
  &__btns{
    // display: flex;
    margin:  0 0 0 auto;
    // height: 100%;
    align-items: center;
    // display:none;
    @include mq(ml,min){
      .nav-scrolled &{
        display: flex;
      }
    }
    .btn{
      height: 50px;
      min-width: auto;
      margin-left: 5px;
      font-size: 14px;
      &:not(.nav__btn--lp){
        @include mq(ml,max){
          border-radius: 0;
          margin:0;
          height: 100%;
          padding: 0;
          flex-direction: column;
          font-size: 10px;
          width: 60px;
          color: #000000;
          background: transparent;
          border: 0;
          border-right: solid 1px #EEEEEE;
          box-shadow: 0 0 0 0;
          position: relative;
          .layout__mv:not(.nav-scrolled,.menu-open) &, .nav--transparent &{
            color: #fff;
          }
          &:after{
            content: "";
            position: absolute;
            left: 0;
            bottom: -5px;
            width: 100%;
            height: 2px;
            background-color: #E60E14;
          }
          &:first-child{
            border-left: solid 1px #EEEEEE;
          }
          &.btn--border{
            &:after{
              display: none;
            }
          }
          .btn__icon{
            margin: 0 0 8px;
            path{
              stroke: #000000;
              .layout__mv:not(.nav-scrolled,.menu-open) &, .nav--transparent &{
                stroke: #fff;
              }
            }
            line{
              stroke: #000000;
              .layout__mv:not(.nav-scrolled,.menu-open) &, .nav--transparent &{
                stroke: #fff;
              }
            }
          }
        }
      }
      &.nav__btn--lp{
        @include mq(ml,max){
          height: 40px;
          padding: 0 25px;
          .nav--lp &{
            padding: 0 10px;
            margin: 0!important;
          }
          .layout__mv:not(.nav-scrolled,.menu-open) &{
            box-shadow: 0 0 0 1px #fff;
            background: transparent;
            color: #fff;
          }
        }
      }
    }
  }
  &__hamburger{
    display: none;
    @include mq(ml,max){
      // margin: 0 0 0 15px;
      display:block;
      flex-shrink: 0;
      width: 60px;
      height: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #000;
      font-size: 10px;
      line-height: 1.5em;
      font-weight: bold;
      .layout__mv:not(.nav-scrolled,.menu-open) &{
        color: #fff;
      }
      &__inner{
        position: relative;
        margin: 0 0 8px;
        height: 16px;
        &:before,&:after{
          content: "";
          position: absolute;
          width: 20px;
          height: 1px;
          top: calc(50% - 4px);
          left: 50%;
          transform: translate(-50%,-50%);
          background-color: #333333;
          transition: 0.4s;
          .menu-open &{
            top: 50%;
            transform: translate(-50%,-50%) rotate(45deg);
          }
          .layout__mv:not(.nav-scrolled,.menu-open) &{
            background-color: #fff;
          }
        }
        &:after{
          top: calc(50% + 4px);
          .menu-open &{
            transform: translate(-50%,-50%) rotate(-45deg);
          }
        }
      }
    }
  }
  &-sp{
    display: none;
    @include mq(ml,max){
      overflow-y: scroll;
      width: 100vw;
      display: block;
      right: -100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      padding: 60px 20px 120px;
      background-color: #fff;
      transition: right 0.4s;
      z-index: 5;
      .menu-open & {
        right: 0;
      }
    }
    &__links{
      li{
        margin: 0;
      }
      a{
        margin: 0;
        display: block;
        font-size: 16px;
        color: $c-dark;
        padding: 16px 0;
        border-top: solid 1px #CACACA;
      }
      &__strong{
        font-weight: bold;
        border-top: 0!important;
      }
    }
    &__terms{
      display: flex;
      flex-wrap: wrap;
      font-size: 14px;
      margin: 50px 0 -1em 0;
      li{
        width: 50%;
        margin-bottom: 1em;
      }
      a {
        color: $c-dark
        ;
      }
    }
  }
}
