.width{
  &-wrap{
    max-width: 1520px;
    margin-right: auto;
    margin-left: auto;
    width: calc(100vw - 80px);
    @include mq(md,max){
      width: calc(100vw - 40px);
    }
  }
  &-lg{
    @extend .width-wrap;
    width: calc(100vw - 160px);
    max-width: 1434px;
    @include mq(md,max){
      width: calc(100vw - 40px);
    }
    &-pc{
      @extend .width-lg;
      @include mq(md,max){
        width: 100vw
      }
    }
  }
  &-md{
    @extend .width-lg;
    max-width: 1190px;
  }
  &-sm{
    @extend .width-lg;
    max-width: 700px;
  }
}
