/**
 * Reset
 */

@if $enable-reset-defaults {
  html,body,body,div,span,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,abbr,address,cite,code,del,dfn,em,ins,kbd,q,samp,small,strong,sub,sup,var,b,i,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,figure,footer,header,hgroup,menu,nav,section,time,mark,audio,video {
    background: transparent;
    border: 0;
    font-size: 100%;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
  }

  article,
  aside,
  figure,
  footer,
  header,
  main,
  nav,
  section {
    display: block;
  }

  * {
    &,
    &:before,
    &:after {
      box-sizing: border-box;
    }
  }
}
