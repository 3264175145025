.error{
  &__img{
    max-width: 140px;
    margin: 0 auto 35px;
    display: block;
  }
  &__heading{
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    span{
      font-size: 31px;
      letter-spacing: 0.3em;
      display: block;
      font-weight: 900;
      margin: 0 0 10px;
    }
  }
}
