.breadcrumb{
  background-color: transparent;
  padding: 15px 0;
  font-size: 12px;
  margin: 0;
  a {
    margin: 0 2em 0 0;
    font-weight: bold;
    color: $c-black;
    position: relative;
    &:after{
      content: "/";
      left: calc(100% + 1em);
      font-weight: initial;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  span {
    color: #343D55;
  }
}
