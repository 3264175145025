/**
 * LAYOUT
 */

.container {
  @include mb-up(md) {
    padding-left: $w-gutter;
    padding-right: $w-gutter;
  }
  background-color: $bgc-container;
  margin: 0 auto;
  // max-width: $mwi-container;
  padding-left: $w-gutter * 0.5;
  padding-right: $w-gutter * 0.5;
  width: 100%;
}

.container-wrap{
  padding-left: $w-gutter * 0.5;
  padding-right: $w-gutter * 0.5;
  @include mb-up(md) {
    padding-left: $w-gutter;
    padding-right: $w-gutter;
  }
}

.container-medium {
  @include mb-up(md) {
    padding-left: $w-gutter;
    padding-right: $w-gutter;
  }
  margin: 0 auto;
  max-width: $mwi-container-medium;
  padding-left: $w-gutter * 0.5;
  padding-right: $w-gutter * 0.5;
}

.container-small {
  @include mb-up(md) {
    padding-left: $w-gutter;
    padding-right: $w-gutter;
  }
  margin: 0 auto;
  max-width: $mwi-container-small;
  padding-left: $w-gutter * 0.5;
  padding-right: $w-gutter * 0.5;
}
