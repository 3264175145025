.heading{
  font-weight: 400;
  font-size: 44px;
  color: #343D55;
  position: relative;
  line-height: 1.5;
  @include mq(md,max){
    font-size: 24px;
  }
  &--border{
    margin: 0 0 60px;
    padding-bottom: 30px;
    @include mq(md,max){
      font-size: 24px;
      padding-bottom: 15px;
      margin: 0 0 40px;
    }
    &:after{
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 120px;
      height: 1px;
      background-color: #343D55;
      @include mq(md,max){
        width: 60px;
      }
    }
  }
  &--inversed{
    color: #333;
    &:after{
      background-color: #333;
    }
  }
  &.text-center,.text-center &{
    &:after{
      left: 50%;
      transform: translateX(-50%);
    }
  }
  span{
    display: block;
    font-size: 14px;
    letter-spacing: 0.4em;
    text-transform: uppercase;
    @include mq(md,max){
      font-size: 12px;
    }
  }
  &--small{
    font-size: 32px;
    @include mq(md,max){
      font-size: 20px;
    }
  }
}
.heading-consul{
  font-size: 36px;
  font-weight: bold;
  color: #343D55;
  text-align: center;
  margin: -40px 0 0;
  @include mq(md,max){
    font-size: 26px;
    line-height: 1.2;
    margin: 20px 0 0;
  }
  span{
    font-weight: normal;
    font-size: 28px;
    line-height: 1.6;
    display: inline-block;
    margin: unset;
    @include mq(md,max){
      font-size: 18px;
      line-height: 1.2;
      margin: 0 0 10px;
    }
  }
}
