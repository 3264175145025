.search{
  &-header{
    border-top: solid 1px #CCD5E5;
    border-bottom: solid 1px #CCD5E5;
    padding: 40px 0;
  }
  &-heading{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-filter{
    display: flex;
    flex-wrap: wrap;
    // width: 100%;
    margin: 0 -15px;
    @include mq(md,max){
      margin: 0 -10px;
    }
    &__item{
      width: calc(100% / 3);
      padding: 0 15px;
      margin: 40px 0 0;
      @include mq(md,max){
        width: 50%;
        padding: 0 10px;
        margin: 20px 0 0;
      }
      &--col-2{
        width: calc(100% / 3 * 2);
        @include mq(md,max){
          width: 100%;
        }
      }
    }
    &__detail{
      width: 100%;
      padding: 0 15px;
      display: none;
      @include mq(md,max){
        padding: 0 10px;
      }
    }
    &__btns{
      width: 100%;
      margin: 40px 0 0;
      padding: 0 15px;
      display: flex;
      flex-wrap: wrap;
      @include mq(md,max){
        margin: 20px 0 0;
      }
      &__inner{
        display: flex;
        @include mq(md,max){
          width: 100%;
          justify-content: space-between;
          margin: 20px 0 0;
        }
      }
      @include mq(md,max){
        padding: 0 10px;
      }
      .search-filter__toggle{
        margin: 0 auto 0 0;
        @include mq(md,max){
          margin: 0 auto;
        }
      }
      .btn{
        margin: 0 0 0 30px;
        @include mq(md,max){
          width: calc(50% - 10px);
          min-width: 0;
          margin: 0;
        }
      }
    }
    &__toggle{
      display: flex!important;
      align-items: center;
      color: $c-black;
      font-size: 14px;
      &:after{
        content: "詳細条件を開く";
      }
      &.active{
        &:before{
          transform: rotate(180deg);
        }
        &:after{
          content: "詳細条件を閉じる";
        }
      }
      &:before{
        content: "";
        width: 10px;
        height: 10px;
        background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%229.4%22%20height%3D%226.1%22%20viewBox%3D%220%200%209.4%206.1%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_36%22%20data-name%3D%22%E3%83%91%E3%82%B9%2036%22%20d%3D%22M6.7%2C8.1%2C2%2C3.4%2C3.4%2C2%2C6.7%2C5.3%2C10%2C2l1.4%2C1.4Z%22%20transform%3D%22translate(-2%20-2)%22%20fill%3D%22%23cacaca%22%2F%3E%3C%2Fsvg%3E');
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
        margin: 0 10px 0 0;
      }
    }
    &__heading{
      font-size: 14px;
      font-weight: bold;
      margin: 0 0 5px;
    }
    input, select{
      border: solid 1px #CACACA;
      padding: 8px 20px;
      font-size: 16px;
      box-sizing: border-box;
      width: 100%;
      @include mq(md,max){
        padding: 5px 10px;
        font-size: 14px;
      }
    }
    select{
      color: #333;
      min-height: 44px;
      border-radius: 0;
      background-color: #fff;
      appearance: none;
      background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%229.4%22%20height%3D%226.1%22%20viewBox%3D%220%200%209.4%206.1%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_36%22%20data-name%3D%22%E3%83%91%E3%82%B9%2036%22%20d%3D%22M6.7%2C8.1%2C2%2C3.4%2C3.4%2C2%2C6.7%2C5.3%2C10%2C2l1.4%2C1.4Z%22%20transform%3D%22translate(-2%20-2)%22%20fill%3D%22%23cacaca%22%2F%3E%3C%2Fsvg%3E');
      background-size: 10px;
      background-position: right 20px center;
      background-repeat: no-repeat;
      @include mq(md,max){
        min-height: 36px;
      }
    }
    &__radio,&__checkbox{
      display: flex;
      margin: 0 -5px -5px;
      &>*{
        padding: 0 5px 5px;
        width: 100%;
      }
      &--inline{
        flex-wrap: wrap;
        &>*{
          width: auto;
        }
      }
      label{
        border: solid 1px #CACACA;
        padding: 9px 19px;
        font-size: 16px;
        box-sizing: border-box;
        text-align: center;
        cursor: pointer;
        @include mq(md,max){
          padding: 5px 10px;
          font-size: 14px;
        }
      }
      input{
        display: none;
        &:checked+label{
          padding: 10px 20px;
          background: linear-gradient(60deg,#D82525, #FF0007);
          color: #fff;
          border: 0px;
          @include mq(md,max){
            padding: 6px 11px;
          }
        }
      }
    }
  }
  &-result{
    font-size: 16px;
    @include mq(md,max){
      font-size: 14px;
    }
    span {
      font-size: 30px;
      color: $c-primary;
      font-weight: bold;
      @include mq(md,max){
        font-size: 22px;
      }
    }
  }
  &-checked{
    font-size: 16px;
    letter-spacing: 0;
    @include mq(md,max){
      font-size: 14px;
      display: flex;
      align-items: flex-end;
    }
    span{
      font-size: 26px;
      font-weight: bold;
      color: $c-primary;
      @include mq(md,max){
        font-size: 22px;
      }
    }
    &__heading{
      font-size: 12px;
      @include mq(md,max){
        margin: 0 10px 0 0;
      }
    }
  }
  &-nav{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 15px 0;
    background-color: #fff;
    box-shadow: 0 0 20px rgba(0,0,0,0.14);
    z-index: 9;
    @include mq(md,max){
      padding: 10px 0;
    }
    &__inner{
      display: flex;
      align-items: center;
      justify-content: center;
      @include mq(md,max){
        flex-wrap: wrap;
      }
    }
    .btn{
      margin: 0 18px 0 0;
      height: 72px;
      @include mq(md,min){
        padding: 0 60px;
      }
      @include mq(md,max){
        height:60px;
        margin: 10px 0 0 0;
        order: 1;
        width: 100%;
      }
    }
    .search-checked{
      margin: 0 20px 0 0;
      text-align: center;
      @include mq(md,max){
        margin: auto;
      }
    }
    .search-result{
      margin: 0 20px 0 auto;
      @include mq(md,max){
        display: none;
      }
    }
    .pagination{
      @include mq(md,max){
        display: none;
      }
    }
  }
  &-list{
    &__heading{
      display: flex;
      align-items: center;
      @include mq(md,max){
        display: block;
      }
      .heading{
        margin: 0 20px 0 0;
      }
      .search-checked{
        margin: 0 20px 0 0;
      }
      .search-result{
        margin: 0 20px 0 auto;
        @include mq(md,max){
          margin: 10px 0 0;
        }
      }
      .pagination{
        @include mq(md,max){
          margin: 40px 0 0;
        }
      }
    }
  }
}
