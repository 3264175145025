.fixedLink{
  position: fixed;
  left: 0;
  bottom: 0;
  height: 100px;
  padding: 0 40px;
  background-color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  box-shadow: 0 0 20px rgba(0,0,0,0.14);
  z-index: 10;
  @include mq(md,max){
    background-color: initial;
    height: auto;
    width: calc(100% - 40px);
    bottom: 20px;
    left: 20px;
    box-shadow: none;
    padding: 0;
  }
  &__text{
    font-size: 16px;
    margin: 0 40px 0 auto;
    @include mq(md,max){
      display: none;
    }
  }
  &--top{
    .btn{
      font-size: 14px;
      flex-direction: column;
      height: 80px;
      padding: 0 80px;
      font-weight: initial;
      @include mq(md,max){
        height: 63px;
        width: 100%;
        font-size: 12px;
        .layout__mv:not(.nav-scrolled) &{
          background: #fff;
          color: $c-primary;
          box-shadow: 0 0 0 1px $c-primary;
        }
      }
      span{
        font-size: 20px;
        font-weight: bold;
        @include mq(md,max){
          margin: 3px 0 0;
          font-size: 17px;
        }
      }
    }
}
}
