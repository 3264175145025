.daterangepicker{
  position: fixed!important;
  top: 50vh!important;
  left: 50vw!important;
  right: auto;
  bottom: auto;
  transform: translate(-50%,-50%)!important;
  font-size: 14px;
  width: 800px!important;
  padding: 60px 0 0 0!important;
  .modal__header{
    border-bottom: solid 1px #DBDBDB;
  }
  &:before,&:after{
    display: none!important;
  }
}
.table-condensed>thead>tr, .table-condensed>tbody>tr, .table-condensed>tfoot>tr, .table-condensed>thead>tr, .table-condensed>tbody>tr, .table-condensed>tfoot>tr{
  border-top: solid 10px #fff;
  border-bottom: solid 10px #fff;
}
.table-condensed>thead>tr>th, .table-condensed>tbody>tr>th, .table-condensed>tfoot>tr>th, .table-condensed>thead>tr>td, .table-condensed>tbody>tr>td, .table-condensed>tfoot>tr>td{
  padding: 3px 16px ;
  border: 0;
}
.daterangepicker .calendar.left{
  padding-left: 30px!important;
}
.daterangepicker.show-calendar .calendar{
  max-width: 100%;
  width: 50%;
  margin: 0;
  // padding: 15px;
}
.daterangepicker .calendar.right{
  padding-right: 30px!important;
}
.daterangepicker .ranges{
  width: 100%;
}
.daterangepicker td.in-range{
    background-color: #E6F1F9;
}
.daterangepicker td.active, .daterangepicker td.active:hover{
  background-color: transparent!important;
  position: relative;
  z-index: 1;
  color: #fff;
  &:before{
    content: "";
    width: 30px;
    height: 30px;
    background-color: #579ED7;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: -1;
  }
  &:after{
    content: "";
    width: 50%;
    height: 30px;
    background-color: #E6F1F9;
    position: absolute;
    left: 0%;
    top: 50%;
    transform: translate(0%,-50%);
    z-index: -2;
  }
  &.start-date {
    &:after{
      left: 50%;
    }
  }
}
.daterangepicker .calendar-table{
  padding: 0!important;
}
.daterangepicker .calendar.left .calendar-table{
  padding-right: 15px!important;
}
.daterangepicker .calendar.right .calendar-table{
  padding-left: 15px!important;
}
.daterangepicker_input{
  display: none;
}
.next.available,.prev.available{
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16);
  padding: 0!important;
  &:before{
    content: "";
    background-image: url('/img/icons/arrow.svg');
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    width: 100%;
  }
  i {
    display: none;
  }
}
.next.available{
  right: 0;
  transform: translate(50%,-50%);
  left: auto;
  &:before{
    transform: rotate(180deg);
  }
}

.daterangepicker .ranges{
  margin: 0;
  width: 100%!important;
  display: flex;
  border-top: solid 1px #DBDBDB;
  padding: 10px 30px;
  align-items: center;
}
.daterangepicker .range_inputs{
  display: flex;
  margin: 0 0 0 auto;
}
.applyBtn{
  background-color: #579ED7;
  order: 1;
  margin: 0 0 0 10px;
  min-width: auto;
  padding: 0 40px;
  border: 0;
  &:hover{
    color: #fff;
    background-color: #579ED7;
    opacity: 0.6;
  }
}

.cancelBtn{
  background-color: #ECEFF5;
  color: #333333;
  min-width: auto;
  border: 0;
  &:hover{
    background-color: #ECEFF5;
    opacity: 0.6;
  }
}
