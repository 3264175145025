
@for $i from 0 through 40 {
  $i: $i * 5;
  .mt-#{$i} {
    margin-top: #{$i}px!important;
  }
  .mr-#{$i} {
    margin-right: #{$i}px!important;
  }
  .mb-#{$i} {
    margin-bottom: #{$i}px!important;
  }
  @include mq(md,min){
    .mt-#{$i}-pc {
      margin-top: #{$i}px!important;
    }
    .mr-#{$i}-pc {
      margin-right: #{$i}px!important;
    }
    .mb-#{$i}-pc {
      margin-bottom: #{$i}px!important;
    }
  }
  @include mq(md,max){
    .mt-#{$i}-sp {
      margin-top: #{$i}px!important;
    }
    .mr-#{$i}-sp {
      margin-right: #{$i}px!important;
    }
    .mb-#{$i}-sp {
      margin-bottom: #{$i}px!important;
    }
  }
}

.m-auto{
  margin: auto;
}

@include mq(md,max){
  .m-auto-sp{
    margin: auto;
  }
}
