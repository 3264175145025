.feature{
  padding-left: 20px;
  padding-right: 20px;
  @include mq(md,max){
    padding-left: 0;
    padding-right: 0;
  }
  &__inner{
    width: calc(100% - 160px);
  }
  &-list{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: -30px -15px;
    @include mq(md,max){
      display: block;
      margin: 0;
    }
  }
  &-item{
    width: calc((100% - 90px) / 3);
    max-width: 418px;
    text-align: center;
    margin: 30px 15px;
    @include mq(md,max){
      width: 100%;
      margin: 20px auto 0;
      &:first-child{
        margin: 0 auto;
      }
    }
    &__img{
      width: 128px;
    }
    &__heading{
      font-size: 24px;
      font-weight: bold;
      color: #343D55;
      margin: 30px 0 0;
      @include mq(md,max){
        font-size: 16px;
        margin: 15px 0 0;
      }
      span{
        font-weight: initial;
        margin: 0 0 10px 0;
        display: block;
        font-size: 14px;
        letter-spacing: 0.4em;
        @include mq(md,max){
          font-size: 10px;
          margin: 0;
          transform: scale(0.7);
        }
      }
    }
    &__text{
      margin: 20px 0 0;
      font-size: 14px;
      line-height: 1.7;
      color: #343D55;
      @include mq(md,max){
        margin: 10px 0 20px;
        font-size: 12px;
      }
    }
  }
  &-consul{
    &__img{
      text-align: center;
      margin: 50px 0 0;
      padding: 0 20px 20px;
      @include mq(md,max){
        padding: 0 10px 20px;
      }
      img{
        margin: 0 auto 0;
      }
    }
    &__bottom-wrap{
      margin: -80px 0 0;
      filter: drop-shadow(0px 10px 20px rgba(204, 213, 229, 1));
    }
    &__bottom{
      width: 100%;
      padding: 160px 0;
      clip-path: polygon(0 calc(0% + 80px), 100% 0, 100% calc(100% - 80px), 0 100%);
      background-color: #ffffff;
      text-align: center;
        @include mq(md,max){
          padding: 48px 0;
          clip-path: polygon(0 calc(0% + 40px), 100% 0, 100% calc(100% - 40px), 0 100%);
        }
      h2{
        font-size: 48px;
        font-weight: bold;
        color: #343D55;
        margin: 0;
        @include mq(md,max){
          font-size: 28px;
        }
        span{
          color: #E60E14;
          background:linear-gradient(transparent 60%, #ECEFF5 60%);
        }
      }
    }
  }

  &__form{
    color: #343D55;
    padding: 45px 0 80px;
    @include mq(md,max){
      padding: 45px 0;
    }
    img{
      margin: 0 auto;
      display: block;
    }
    h2{
      font-size: 36px;
      font-weight: 600;
      text-align: center;
      margin: 0 0 0;
      span{
        background: linear-gradient(transparent 70%, #FDA6A6 70% ,transparent 200%);
      }
    }
    &-outer{
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      max-width: 720px;
      margin: 48px auto 40px;
      @include mq(md,max){
        flex-direction: column;
      }
    }
    &-inner{
      width: 50%;
      margin: unset;
      @include mq(md,max){
        flex-direction: column;
        width: 90%;
        margin: 0 auto 20px;
      }
      p {
        font-size: 20px;
        font-weight: bold;
        @include mq(md,max){
          font-size: 16px;
        }
        span{
          font-weight: normal;
        }
      }
      select{
        cursor: pointer;
        width: 100%;
        max-width: 340px;

        background-image: url('/img/sale/consul-form-arrow.svg');
        padding: 7px 18px 9px;
        background-position: right 22px center;
        background-repeat: no-repeat;
        background-size: 10px 15px;
        border: 1px solid #9DA9C9;
        margin: 10px 0 0;
        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: none;
        border-radius: 0;
        height: 64px;
        cursor: pointer;
        font-size: 24px;
        font-weight: bold;
        color: #343D55;
        @include mq(md,max){
          max-width: unset;
          font-size: 16px;
          padding: 4px 14px 4px;
          height: 48px;
        }
      }
    }
    &-submit{
      text-align: center;
      width: 100%;
      p{
        margin: 40px auto 20px;
        font-size: 16px;
        @include mq(md,max){
          font-size: 12px;
          margin: 0px auto 10px;
        }
      }
    }
    &-result{
      background: #fff;
      max-width: 1136px;
      margin: 0 auto;
      text-align: center;
      padding: 80px 40px;
      @include mq(md,max){
        padding: 32px 20px;
        margin: 0 20px;
      }
      h3{
        font-size: 20px;
        font-weight: normal;
        margin: 0 0 10px;
        span{
          font-size: 44px;
          font-weight: bold;
          color: #E60E14;
        }
      }
      p{
        font-size: 16px;
        margin: 0 0 34px;
        @include mq(md,max){
          font-size: 14px;
        }
      }
      .btn{
        width: 100%;
        max-width: 500px;
        font-size: 24px;
        height: 84px;
        @include mq(md,max){
          max-width: 280px;
          height: 50px;
          font-size: 17px;
        }
      }
    }
  }
}