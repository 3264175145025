/**
 *  SETTINGS
 */

$enable-grid-classes: true !default; // generate grid classes
$enable-reset-defaults: true !default; // reset default browser values

// colors
$c-black:       #000000 !default;
$c-darker:      #1A1311 !default;
$c-dark:        #333333 !default;
$c-gray:        #999999 !default;
$c-light:       #DBDBDB !default;
$c-lighter:     #F7F7F7 !default;
$c-white:       #ffffff !default;

$c-primary:     #E60E14 !default;
$c-secondary:   #D82525 !default;

$c-success:     #3DD572 !default;
$c-info:        #00BAFF !default;
$c-warning:     #E8A50D !default;
$c-error:       #E8494F !default;

$c-text:          $c-dark !default;
$c-link:          $c-primary !default;
$c-link-hover:    rgba($c-primary, .8) !default;

$bgc-body:        $c-white !default;

$c-map: (
  'primary':              $c-primary,
  'secondary':            $c-secondary,
  'success':              $c-success,
  'info':                 $c-info,
  'warning':              $c-warning,
  'error':                $c-error,
  'black':                $c-black,
  'darker':               $c-darker,
  'dark':                 $c-dark,
  'gray':                 $c-gray,
  'light':                $c-light,
  'lighter':              $c-lighter,
  'white':                $c-white,
) !default;

// Social colors
$c-fb: rgb(59, 89, 182) !default;
$c-tw: rgb(64, 153, 255) !default;
$c-googleplus: rgb(215, 61, 50) !default;
$c-pinterest: rgb(203, 32, 39) !default;
$c-skype: rgb(18, 165, 244) !default;
$c-spotify: rgb(129, 183, 26) !default;
$c-instagram: #4e433c !default;
$c-tumblr: #2b4964 !default;
$c-vimeo: #86b32d !default;
$c-soundcloud: #f76600 !default;
$c-youtube: #ff3333 !default;
$c-linkedin: #4875b4 !default;
$c-flickr: #fe0883 !default;
$c-foursquare: #8fd400 !default;

// Shadows
$bxsh-small:    0 0 4px rgba(0, 0, 0, .2) !default;
$bxsh-medium:   0 0 8px rgba(0, 0, 0, .2) !default;
$bxsh-large:    0 0 16px rgba(0, 0, 0, .2) !default;
$bxsh: $bxsh-medium;

// Typography
$fw-light: 300 !default;
$fw-normal: 400 !default;
$fw-bold: 600 !default;
$fw-headings: $fw-light !default;

@font-face {
  font-family: 'Noto Sans Japanese';
  font-weight: 100;
  src:
    local('sans-serif'),
    url('../../fonts/NotoSansJP-Light.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 400;
  src:
    local('sans-serif'),
    url('../../fonts/NotoSansJP-Regular.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: bold;
  font-weight: 700;
  src:
    local('sans-serif'),
    url('../../fonts/NotoSansJP-Bold.woff') format('woff');
    font-display: swap;
}
@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: bold;
  font-weight: 900;
  src:
    local('sans-serif'),
    url('../../fonts/NotoSansJP-Black.woff') format('woff');
  font-display: swap;
}

$ff-base: 'Noto Sans Japanese', sans-serif;
$fw-base: 400 !default;
$fz-base: 16px !default;
$lh-base: 1.5em !default;

$fz-huge: 36px !default;
$fz-big: 24px !default;
$fz-medium: $fz-base !default;
$fz-small: 12px !default;

$lh-huge: 1.3em !default;
$lh-big: 1.3em !default;
$lh-medium: $lh-base !default;
$lh-small: 1.3em !default;

// Body
$c-body: $c-text !default;
$ff-body: $ff-base !default;
$fz-body: $fz-base !default;
$fw-body: $fw-base !default;
$lh-body: $lh-base !default;

// Border
$bdc-base: #d5d9db !default;
$bd-medium: 1px solid $bdc-base !default;
$bdr-base: 3px !default;
$bdr-medium: $bdr-base !default;
$bdr-rounded-corners: 5px !default;

// Defaults (HTML elements)
$fw-strong: $fw-bold !default;
$mb-p: 1.5em !default;
$mb-ul: 1em !default;
$mb-li: .5em !default;

// Margin
$m-xsmall: 4px !default;
$m-small: 8px !default;
$m-medium: 16px !default;
$m-big: 36px !default;
$m-huge: 48px !default;

// Padding
$p-small: 4px !default;
$p-medium: 8px !default;
$p-big: 16px !default;
$p-huge: 40px !default;

// Layout
$bgc-container: transparent !default;
$mwi-container: 1380px !default;
$mwi-container-medium: 944px !default;
$mwi-container-small: 400px !default;
$w-gutter: 40px !default;

// Grid
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

$grid-columns: 12 !default;
$grid-gutter-width: $w-gutter !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) !default;

/**
 *  COMPONENTS
 */

// Badges
$c-badge: $c-text !default;
$c-badge-colored: $c-white !default;
$bg-badge: $c-light !default;
$bdr-badge: $bdr-medium !default;
$lh-badge: 1.2em !default;
$p-badge: $p-medium $p-big !default;
$fz-badge-big: 1.3em !default;
$fz-badge-small: .7em !default;
$badges-color-map: (
  primary:              $c-primary,
  secondary:            $c-secondary,
  dark:                 $c-dark,
  light:                $c-gray,
  success:              $c-success,
  error:                $c-error,
  warning:              $c-warning
) !default;

// Buttons
$bg-button: $c-primary !default;
$c-button: $c-white !default;
$c-button-transparent: $c-primary !default;
$c-button-outlined: $c-primary !default;
$bdc-button: $bg-button !default;
$bd-button: 1px solid $bdc-button !default;
$bdr-button: 200px !default;
$ff-button: $ff-body !default;
$fz-button: $fz-medium !default;
$fw-button: $fw-bold !default;
$lh-button: $lh-body !default;
$m-button: 0 $m-small $m-medium $m-small !default;
$p-button: $p-medium $p-big !default;
$tt-button: uppercase !default;

$fz-button-big: $fz-big !default;
$p-button-big: $p-big $p-huge !default;

$fz-button-small: $fz-small !default;
$p-button-small: $p-small $p-big !default;

// Forms
$bgc-form: $c-lighter !default;
$c-form: $c-text !default;
$bdr-form: $bdr-medium !default;
$bd-form: $bd-medium !default;
$fz-form: $fz-body !default;
$bgc-form-focus: lighten($bgc-form, 5%) !default;
$trs-form: background-color .2s ease-in-out, padding .2s ease-in-out !default;
$c-form-placeholder: $c-gray !default;
$mih-form-textarea: 120px !default;
$p-form: $p-medium $p-big !default;
$p-form-focus: $p-medium 0 !default;
$size-form-input-icon: 16px !default;
$c-form-input-icon: $c-gray !default;
$bd-form-error: 1px solid $c-error !default;
$bgc-form-error: $bgc-form !default;

// Loading bar
$h-loading-bar: 6px !default;
$w-loading-bar: 300px !default;
$z-loading-bar: 1000 !default;

// Loading spinner
$bg-loading-spinner: $c-secondary !default;
$size-loading-spinner-inner: 9px !default;
$size-loading-spinner: 30px !default;
$z-loading-spinner: 1000 !default;

// Notification
$bdr-notification: $bdr-medium !default;
$c-notification: $c-white !default;
$mb-notification: $m-big !default;
$p-notification: $p-big !default;

// Paginator
$mr-paginator-item: $m-xsmall !default;
$bdr-paginator-item: $bdr-medium !default;
$bg-paginator-item: $c-light !default;
$c-paginator-item: $c-text !default;
$bg-paginator-item-active: $c-primary !default;
$c-paginator-item-active: $c-white !default;
$p-paginator-item: $p-medium $p-big !default;

// Table
$bgc-table-header: $bgc-body !default;
$bgc-table: $c-light !default;
$bd-table: $bd-medium !default;
$c-table-heading: $c-gray !default;
$c-table-text: $c-text !default;
$fw-table-heading: normal !default;
$mwi-table: 100% !default;
$p-table-cell: $p-medium !default;
$p-table-heading: $p-medium !default;
$w-table: 100% !default;
$fz-table-heading: $fz-small !default;

// Tabs
$bdb-tabs-item-selected: 3px solid $c-primary !default;
$bdb-tabs-item: 3px solid transparent !default;
$bdb-tabs: $bd-medium !default;
$c-tabs-item-hover: $c-primary !default;
$c-tabs-item-selected: $c-primary !default;
$c-tabs-item: $c-gray !default;
$m-tabs-item: 0 $m-medium 0 0 !default;
$miw-tabs-item: 70px !default;
$p-tabs-item: $p-big !default;

// Tags
$bg-tag-dot: $bgc-body !default;
$bg-tag: $c-light !default;
$bdr-tag: $bdr-medium 0 0 $bdr-medium !default;
$c-tag: $c-text !default;
$lh-tag: 16px !default;
$m-tag: 0 $m-medium $m-medium 0 !default;
$p-tag: $p-medium !default;
$size-tag-dot: 5px !default;
