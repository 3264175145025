.alternate{
  &-list{
    counter-reset: alternateNumber 0;
  }
  &-item{
    max-width: 1437px;
    margin: 0 auto 160px;
    display: flex;
    align-items: center;
    @include mq(md,max){
      display: block;
      margin: 0 auto 80px;
    }
    &--icon{
      max-width: 960px;
      padding: 0 20px 0;
      @include mq(md,max){
        padding:  0;
      }
    }
    &:last-child{
      margin: 0 auto;
    }
    &:nth-child(even){
      @include mq(md,min){
        flex-direction: row-reverse;
        .alternate-item__contents{
          width: calc(100% - 122px - (50% - 105px));
          margin: 0 30px 0 auto;
        }
      }
    }
    &__contents{
      width: calc(50% - 17px);
      margin: 0 0 0 auto;
      @include mq(md,max){
        width: 100%;
      }
      &--icon{
        width: 100%;
        margin: 0 0 0 60px;
        max-width: 600px;
        @include mq(md,max){
          width: 100%;
          max-width: unset;
          margin: 0 auto 60px;
        }
      }
    }
    &__img{
      width: calc(50% - 135px);
      @include mq(md,max){
        width: 100%;
        margin: 0 0 30px;
      }
      &--icon{
        width: auto;
        margin: 0 40px 0;
        max-height: 140px;
        max-width: unset;
        @include mq(md,max){
          max-width: 160px;
          width: 100%;
          margin: 0 auto 20px;
          text-align: center;
        }
      }
    }
    &__heading{
      font-size: 32px;
      position: relative;
      line-height: 1.5;
      @include mq(md,max){
        font-size: 20px;
        padding-left: 30px;
      }
      &:before{
        counter-increment: alternateNumber 1;
        content: counter(alternateNumber) ".";
        position: absolute;
        top: 0;
        left: -60px;
        @include mq(md,max){
          left: 0;
        }
      }
    }
    &__text{
      margin: 40px 0 0;
      @include mq(md,max){
        margin: 20px 0 0;
        font-size: 14px;
      }
    }
    &__list{
      margin: 40px 0 0 16px;
      li{
        list-style-type: '・';
        list-style-position: outside;
      }
    }
  }
}
