.section{
  padding: 120px 0;
  border-top: solid 1px #CACACA;
  @include mq(md,max){
    padding: 40px 0;
  }
  .mv+&{
    border-top: 0;
  }
  &__text{
    font-size: 18px;
    line-height: 2;
    @include mq(md,max){
      font-size: 14px;
      line-height: 1.8;
    }
  }
  .breadcrumb+&{
    border-color: #ECEFF5;
  }
  &--noborder{
    border: 0;
  }
  &--sale{
    background-color: #ECEFF5;
    padding: 120px 0 20px;
    @include mq(md,max){
      padding: 40px 0;
    }
  }
  &--about{
    background-color: #BED6E4;
    padding: 120px 0 20px;
    @include mq(md,max){
      padding: 40px 0;
    }
  }
}
