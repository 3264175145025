.magazine{
  &-header{
    background-color: #ECEFF5;
    padding: 55px 0 0 0;
    @include mq(md,max){
      background: linear-gradient(-135deg,#F7F8FB, #ECEFF5);
      padding: 45px 0 40px;
    }
    &__inner{
      display: flex;
      align-items: center;
      position: relative;
      @include mq(md,max){
        display: block;
        text-align: center;
      }
    }
    &__heading{
      font-size: 32px;
      font-weight: bold;
      display: flex;
      align-items: center;
      position: absolute;
      // top: 3%;
      @include mq(md,max){
        font-size: 24px;
        display: block;
        text-align: left;
        bottom: -5px;
        // &:after{
        //   content: "";
        //   width: 60px;
        //   height: 1px;
        //   background-color: #343D55;
        //   margin: 10px auto 0;
        //   display: block;
        // }
      }
      span{
        // display: block;
        font-size: 10px;
        letter-spacing: 0.2em;
        font-weight: 500;
        margin-left: 17px;
        @include mq(md,max){
          font-size: 10px;
          letter-spacing: 0.4em;
          position: absolute;
          bottom: -15px;
          right: -5px;
          width: 100px;
          }
      }
    }
    &__heading a {
      color: #07153E;
    }
    &__lead{
      color: #07153E;
      font-size: 14px;
      line-height: 1.4;
      margin: 0 0 0 60px;
      padding: 0 0 0 60px;
      // border-left: solid 1px #9DA9C9;
      position: absolute;
      // top: 50%;
      left: 15%;
      @include mq(md,max){
        font-size: 12px;
        text-align: center;
        border: 0;
        padding: 0;
        margin: 20px 0 0;
        position: absolute;
        bottom: -21px;
        right: 36%;
        letter-spacing: 0.17em;
        line-height: 1.5rem;
      }
      @include mq(sm,max){
        right: 30%;
      }
      @include mq(512px,max){
        right: -40px;
      }
    }
    &__space {
      letter-spacing: 0.18em;
      @include mq(md,max) {
        letter-spacing: 0.25em;
      }
    }
    &__img{
      margin: 20px 0 0;
    }
  }
  &-nav{
    display: flex;
    flex-wrap: wrap;
    padding: 15px 0;
    background-color: #07153E;
    justify-content: center;
    height: 67px;
    @include mq(md,max) {
      display: grid;
      height: auto;
    }
    a {
      margin: 0 30px;
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      padding: 5px 0;
      position: relative;
      @include mq(md,max) {
        text-align: center;
        margin: 0;
        width: calc(100vw - 40px);
      }
      &.current{
        border-bottom: solid 3px #fff;
        @include mq(md,max) {
          border-bottom: none;
        }
      }
    }
    a:hover {
      border-bottom: solid 3px #fff;
      color: #fff;
      text-decoration: none;
      opacity: 1;
      @include mq(md,max){
        border-bottom: none;
      }
    }
    a::after {
      @include mq(md,max) {
        content:"";
        display:block;
        width:100%;
        height:5px;
        border-bottom: solid 1px #CCD5E5;
        position:absolute;
      }
    }
  }
  &-nav-box {
    position: absolute;
    bottom: -25px;
    right: 0;
    display: flex;
    p {
      padding: 16px 30px;
      font-weight: bold;
      color: #07153E;
      background-color: #fff;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      margin-right: 2px;
      &.current{
        background-color: #07153E;
        color: #fff;
      }
    }
  }
  &-new{
    padding: 45px 0;
    border-bottom: solid 1px #CACACA;
    // background: linear-gradient(0.25turn, transparent, #ECEFF5, transparent);
    @include mq(md,max){
      background: transparent;
      padding: 30px 0;
    }
    &__heading{
      color:#343D55;
      max-width: 1190px;
      margin: 60px auto;
      // margin: 0 0 45px;
      font-size: 28px;
      font-weight: 400;
      b {
        font-weight: bold;
      }
      @include mq(md,max){
        font-size: 18px;
        text-align: left;
        margin: 30px 0;
        span {
          display: none;
        }
      }
    }
    @include mq(md,min){
      .magazine-list{
        max-width: 100%;
      }
      .magazine-list__title{
        font-size: 28px;
      }
      // .magazine-list__header{
        // justify-content: space-between;
      // }
    }
  }
  &-list{
    display: flex;
    max-width: 1190px;
    margin: 0 auto -45px;
    justify-content: space-between;
    flex-wrap: wrap;
    p {
      text-align: left;
      @include mq(md,max) {
        text-align: left;
      }
    }
    &__category {
      max-width: 1190px;
      margin: 0 auto -45px;
      font-size: 28px;
      color: #343D55;
      padding: 60px 0;
      display: flex;
      font-weight: bold;
    }
    &__item{
      color: #343D55;
      width: calc(50% - 15px);
      // width: 100%;
      margin: 0 0 45px;
      @include mq(md,max){
        width: 100%;
      }
    }
    &__img{
      background-color: #ECEFF5;
      padding-bottom: 40%;
      background-size: cover;
      background-position: center;
    }
    &__header{
      margin: 10px 0 0;
      display: flex;
      align-items: center;
    }
    &__title{
      font-size: 20px;
      font-weight: bold;
      line-height: 1.5;
      margin: 10px 0 0;
      @include mq(md,max){
        font-size: 14px;
      }
    }
    &__title a {
      color: #3B4043;
    }
    &__headline {
      font-size: 28px;
      font-weight: bold;
      max-width: 1190px;
      margin: 60px auto 0;
      color: #343D55;
      @include mq(md,max){
        font-size: 14px;
      }
    }
  }
  &-more{
    .btn{
      min-width: 336px;
    }
  }
  &-tagsNav{
    // max-width: 1190px;
    margin: 0 auto;
    text-align: center;
    background-color: #07153E;
    padding: 43px 0 23px 0;
    @include mq(md,max){
      background-color: #07153E;
    }
    &__title {
      font-size: 28px;
      font-weight: bold;
      color: #fff;
      margin-bottom: 50px;
      @include mq(md,max){
        font-size: 16px;
        margin-bottom: 20px;
      }
    }
    &__hidden{
      height: 60px;
      overflow: hidden;
      @include mq(md,max){
        height: 45px;
      }
    }
    &__list{
      // background: linear-gradient(0.25turn, transparent, #ECEFF5, transparent);
      margin: -5px 30px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 10px 0;
      @include mq(md,max){
        background: transparent;
        margin: 0;
        padding: 0 0 3px;
        display: flex;
      }
      &>a {
        font-size: 14px;
        font-weight: bold;
        margin: 0 10px 15px;
        color: #07153E;
        padding: 10px 30px;
        background-color: #fff;
        border-radius: 30px;
        &:before{
          content: "# ";
        }
        @include mq(md,max){
          font-size: 12px;
          // border: solid 3px #ECEFF5;
          // widthd: 50%;
          margin: 7px 5px;
          background-color: #fff;
          height: 37px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px 15px;
        }
      }
    }
    &__arrow {
      display: inline-block;
      width: 18px;
      height: 18px;
      margin-top: 37px;
      // transform: rotate(45deg);
    }
    &__category{
      font-weight: bold;
      &:before{
        display: none;
      }
    }
    &__heading{
      font-size: 28px;
      text-align: center;
      font-weight: bold;
      margin: 0 0 50px;
      width: 100%;
      color: #fff;
      font-weight: bold;
      @include mq(md,max){
        padding: 15px 0;
        font-size: 10px;
        margin: 0;
      }
      &--toggle{
        position: relative;
        &:after{
          content: "";
          width: 10px;
          height: 10px;
          transform: translateY(-50%) rotate(45deg);
          border-right: solid 1px #9DA9C9;
          border-bottom: solid 1px #9DA9C9;
          position: absolute;
          right: 20px;
          top: 45%;
          transition: 0.4s;
        }
        &.active{
          &:after{
            transform: translateY(-50%) rotate(-135deg);
            top: 55%;
          }
        }
        &+*{
          display: none;
        }
      }
    }
  }
  &-register{
    padding: 60px 20px;
    border: solid 1px #CACACA;
    text-align: center;
    @include mq(md,max){
      padding: 30px 15px;
    }
    &__message {
      font-size: 32px;
      margin: 0 0 30px;
      line-height: 44px;
      @include mq(md,max){
        font-size: 24px;
        margin: 0 0 20px;
      }
    }
    &__text{
      font-size: 24px;
      margin: 0 0 30px;
      @include mq(md,max){
        font-size: 14px;
        margin: 0 0 20px;
      }
    }
  }
  &-tags{
    margin: -5px -12px;
    display: flex;
    flex-wrap: wrap;
    @include mq(md,max){
      margin: -5px;
    }
    &>* {
      font-size: 14px;
      margin: 5px 12px;
      color: #343D55;
      line-height: 1.2;
      @include mq(md,max){
        font-size: 12px;
        margin: 5px;
      }
      &:before{
        content: "# ";
      }
    }
  }
  &-categorys{
    display: flex;
    margin: 0 0 0 0;
    flex-wrap: wrap;
    &>* {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0 20px;
      background-color: #07153e;
      color: #fff;
      margin-right: 5px;
      @include mq(md,max){
        height: 30px;
        font-size: 12px;
        padding: 0 10px;
      }
    }
  }
  &-categorys a:hover {
    color: #FFF;
  }
  &-date{
    margin: 0 0 0 20px;
    flex-shrink: 0;
    font-size: 12px;
    letter-spacing: 0;
  }
  &-detail__date{
    margin: 0 0 0 20px;
    flex-shrink: 0;
    font-size: 12px;
    letter-spacing: 0;
  }
  &-detail__date::before {
    content: url('../../../public/img/magazine/clock.png');
    display: inline-block;
    // width: 3px;
    // height: 3px;
    margin-right: 10px;
  }
  &-detail__modified{
    margin: 0 0 0 20px;
    flex-shrink: 0;
    font-size: 12px;
    letter-spacing: 0;
  }
  &-detail__modified::before {
    content: url('../../../public/img/magazine/rotate.png');
    display: inline-block;
    // width: 3px;
    // height: 3px;
    margin-right: 10px;
  }
  &-detail{
    border-top: solid 1px #ECEFF5;
    padding: 30px 0 80px;
    @include mq(md,max){
      padding: 20px 0 80px;
      border: 0;
    }
    &__inner{
      max-width: 946px;
      margin: 0 auto;
      position: relative;
    }
    &__share{
      &__wrap{
        position: absolute;
        right: calc(100% + 30px);
        top: 0;
        height: 100%;
        width: 44px;
      }
      flex-direction: column;
      text-align: center;
      span{
        line-height: 1;
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 0.2em;
        margin: 0 0 0 0.3em;
      }
      &>*{
        margin: 0 0 10px;
        display: flex;
        @include mq(md,max){
          margin: 10px;
          display: inline-block;
        }
      }
    }
    &__img{
      padding-bottom: 40%;
      background-size: cover;
      background-position: center;
      background-color: #ECEFF5;
    }
    &__contents{
      max-width: 702px;
      margin: 0 auto 0;
    }
    &__thum {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__record {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: #343D55;
      position: initial;
      // right: 13%;
    }
    &__title{
      font-size: 28px;
      line-height: 1.5;
      margin: 20px 0 0;
      font-weight: bold;
      color: #343D55;
      @include mq(md,max){
        margin: 30px 0 0;
        font-size: 18px;
      }
    }
    &__heading{
      padding: 30px 0;
      border-bottom: solid 1px #CCD5E5;

      @include mq(md,max){
        padding: 20px 0;
      }
    }
    &__lead{
      margin: 60px 0;
    }
    &__wysiwyg{
      font-size: 16px;
      color: #3B4043;
      @include mq(md,max){
        font-size: 14px;
      }
      p {
        margin: 0 0 30px 0;
        line-height: 1.75;
        @include mq(md,max){
          margin: 10px 0;
        }
      }
      // &>*{
      //   &:first-child{
      //     margin-top: 0;
      //   }
      //   &:last-child{
      //     margin-bottom: 0;
      //   }
      // }
      h2{
        font-size: 24px;
        font-weight: bold;
        margin: 40px 0 10px;
        line-height: 1.4;
        @include mq(md,max){
          margin: 20px 0 10px;
          font-size: 24px;
        }
      }
      h3{
        font-size: 20px;
        font-weight: bold;
        margin: 40px 0 10px;
        line-height: 1.4;
        @include mq(md,max){
          margin: 20px 0 10px;
          font-size: 20px;
        }
      }
      h3{
        font-size: 18px;
        font-weight: bold;
        margin: 40px 0 10px;
        line-height: 1.4;
        @include mq(md,max){
          margin: 20px 0 10px;
          font-size: 18px;
        }
      }
      h4,h5,h6{
        font-size: 16px;
        font-weight: bold;
        margin: 20px 0 10px;
        line-height: 1.4;
        @include mq(md,max){
          margin: 20px 0 10px;
          font-size: 16px;
        }
      }
      a{
        text-decoration: none;
      }
      ul{
        padding-left: 1.5em;
        list-style-type: disc;
        li{
          list-style-type: disc;
        }
      }
      ol{
        padding-left: 1.5em;
        list-style-type: decimal;
        margin-bottom: 1em;
        li{
          list-style-type: decimal;
        }
      }
      th,td {
        border: solid 1px;
        padding: 4px;
      }
      table {
        border-collapse:  collapse;
        margin: 20px 0 10px;
      }
      figcaption {
        font-size: 12px;
      }
      blockquote {
        margin: 20px 0;
      }
    
      blockquote p {
          padding: 15px;
          background: #eee;
          border-radius: 5px;
          margin: 0 0 10px!important;
      }
    
      blockquote p::before {
          content: '\201C';
      }
    
      blockquote p::after {
          content: '\201D';
      }
      ol li {
        list-style-type: decimal!important;
        margin: 8px 0 0;
      }
        ul li {
          list-style-type: disc!important;
          margin: 8px 0 0;
      }
    }
    &__index{
      background-color: #ECEFF5;
      padding: 30px;
      @include mq(md,max){
        padding: 20px;
        margin: 0 -20px;
        width: calc(100% + 40px);
      }
      &__heading{
        font-size: 24px!important;
        font-weight: bold;
        margin: 0!important;
        @include mq(md,max){
          font-size: 18px!important;
        }
      }
      li {
        margin: 20px 0 0;
        @include mq(md,max){
          margin: 14px 0 0;
        }
        a {
          color: #3B4043;
          text-decoration: none;
        }
      }
    }
  }
  &-relation{
    padding: 45px 80px;
    background: linear-gradient(0.25turn, transparent, #ECEFF5, transparent);
    @include mq(md,max){
      padding: 25px 20px;
      border-top: solid 1px #CCD5E5;
    }
    &__inner{
      max-width: 1434px;
      margin: 0 auto;
    }
    &__heading{
      font-size: 28px;
      font-weight: bold;
      margin: 0 0 45px;
      @include mq(md,max){
        font-size: 18px;
        text-align: left;
        margin: 0 0 24px;
      }
    }
    &__item{
      // background-color: #9DA9C9;
      width: calc(50% - 115px);
      margin-right: 30px;
      @include mq(md,max){
        width: 100%;
      }
      .magazine-list__item{
        width: 100%;
        margin: 0;
        background-color: transparent;
      }
    }
    .swiper-pagination{
      justify-content: flex-start;
      // margin: 30px -10px 0;
      @include mq(md,max){
        margin: 10px -10px;
        justify-content: center;
      }
    }
  }
  &-category{
    &__header{
      @include mq(md,max){
        background: linear-gradient(20deg, #ECEFF5,#F7F8FB);
        margin: 0 0 20px;
      }
      .magazine-header{
        background: #ECEFF5;
        padding-bottom: 0;
        border-bottom: 0;
      }
    }
  }
  &-search__inner {
    // background-color: #fff;
    max-width: 700px;
    margin: 0 auto;
    height: 67px;
    padding: 10px;
  }
}
#magazine-search__box {
  position: relative;
}

.swiper-button-disabled
#magazine-search__box{
  // position:relative;
  // max-width:300px;	
  // margin-bottom:20px;
  }
  .magazine-search__text{
  padding:0 10px; 
  position:absolute; 
  // left:0;
  // top:0; 
  border-radius:2px;
  outline:0;
  background:#fff;	
  border: none;
  width: 700px;
  height: 46px;
  @include mq(md,max) {
    width: 100%;
  }
  }
  .magazine-search__btn{
  height:auto;
  position:absolute; 
  right: -1%;
  margin-top: 8px;
  background:none;
  color:#666;
  border:none;
  font-size:20px;
  @include mq(md,max) {
    right: 0%;
  }
  }
  .magazine-search__btn:hover {
    opacity: 0.7;
    transition:all 0.5s ease;
    @include mq(md,max){
      opacity: 1;
      transition: none;
    }
  }

.toggle_title {
	margin: 0 auto;
	position: relative;
	transition: 0.3s;
	cursor: pointer;
}
.toggle_title::after {
	content: "";
	display: inline-block;
	position:absolute;
	top: 0%;
	right: 0%;
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-top: 30px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
	transition: 0.2s;
  transform: translate(0%, 0px)rotate(45deg);
  @include mq(md,max){
    top: -100%;
    transform: translate(0%, 0px)rotate(45deg);
  }
}
.toggle_title.selected:after {
	transform: rotate(225deg);
	transition: 0.2s;
}
.toggle_txt {
	display: none;
    // @include mq(md,max){
  //   display: flex;  
  // }
}

// タブのCSS
.tab-test input {
  display: inline-block;
  text-align: right;
  width: 7em;
}
.tab-group{
  display: flex;
  justify-content: right;
  margin-bottom: 0;
  @include mq(md,max){
    position: fixed;
    bottom: 0;
    justify-content: space-between;
  }
}
.tab{
  // flex-grow: 1;
  padding: 16px 30px; 
  list-style:none;
  text-align:center;
  cursor:pointer;
  margin-bottom: 0;
  border-radius: 0.75em 0.75em 0px 0px;
  font-weight: bold;
  background-color: #fff;
  margin: 0 1px;
  box-shadow: 0px 0px 4px rgba(0,0,0,0.4);
  @include mq(md,max){
    font-size: 14px;
    height: 56px;
    padding: 16px; 
  }
}
.tab-back{
  margin-bottom: 0;
  border-radius: 0.75em 0.75em 0px 0px;
  // background-color: #fff;
  margin: 0 1px;
  mix-blend-mode: lighten;
  @include mq(md,max){
    height: 56px;
    padding: 16px; 
  }
}
.tab-back.is-active{
  background-color: #07153E;
}
.tab:hover{
  img {
    opacity: 0.6;
    @include mq(md,max){
      opacity: 1;
    }
  }
  color: #07153e63;
  transition:all 0.5s ease;
  @include mq(md,max){
    color: #07153E;
    opacity: 1;
    transition: none;
  }
}
.tab-glass-sp {
  width: 50%!important;
}
.panel-group{
  border-top:none;
  background:#fff;
  z-index: 2;
  position: relative;
}
.magazine-scroll__inner {
  z-index: 1;
  position: relative;
}
.panel{
  background: #07153E;
  display:none;
}
.tab.is-active{
  background: #07153E;
  color:#FFF;
  transition: all 0.2s ease-out;
  opacity: 1!important;
}
.panel.is-show{
  display:block;
}
.magazine-links__contents {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: solid 1px #CACACA;
  padding: 50px 0 66px 0;
  margin: 60px 0;
  text-align: center;
  @include mq(md,max) {
    margin: 0;
  }
}

.ScrollTop {
  position: fixed;
  right: 10px;
  bottom: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #5bc8ac;
  color: #fff;
  text-decoration: none;
  opacity: 0;
  transition: all 0.65s;
}
.ScrollTop.isActive {
  opacity: 1;
  transition: all 0.65s;
}
.magazine-tabscroll {
  position: fixed;
  display: none;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 10;
  /* 1. メニューを画面下へ隠す */
  transform: translateY(100%);
  transition: .3s;
  /* 2. 0.3秒かけて表示する */
  ul {
    background: transparent;
    border-top:none;
    display: flex;
    justify-content: right;
    margin-bottom: -1px;
    @include mq (md,max){
      justify-content: space-around;
    }
  }
  li{
    padding: 16px 30px;
    list-style: none;
    text-align: center;
    cursor: pointer;
    margin-bottom: 0;
    border-radius: 0.75em 0.75em 0px 0px;
    font-weight: bold;
    background-color: #fff;
    margin: 0 1px;
    color: #07153E;
    @include mq (md,max){
      width: 100%;
      padding: 16px 5px;
    }
  }
}
.magazine-tabscroll__group {
  border-top: none;
  background-color: #fff;
}
.magazine-scroll__tab {
  // flex-grow: 1;
  padding: 16px 30px; 
  list-style:none;
  text-align:center;
  cursor:pointer;
  margin-bottom: 0;
  border-radius: 0.75em 0.75em 0px 0px;
  font-weight: bold;
  background-color: #fff;
  margin: 0 1px;
  @include mq(md,max){
    height: 56px;
    padding: 16px; 
  }
}
.active{
  transform: translate(0);
  display: block;
}

.swiper-navigation-wrapper{
  display:flex;
  align-items: center;
  margin: 30px 0px 0;
}
.swiper-navigation-wrapper{
  
  .swiper-history-next,.swiper-history-prev{
    display: block;
    height: 30px;
    width: 30px;
    background-color: transparent;
    position:relative;
    cursor: pointer;
  }

  .swiper-button-disabled::before{
    opacity: 0.3;
  }
  .swiper-button-disabled::before{
    opacity: 0.3;
  }

  .swiper-button-disabled.swiper-button-lock::before{
    opacity: 0.3;
  }

  .swiper-history-prev.swiper-button-disabled.swiper-button-lock::before{
    opacity: 0.3;
  }
  .swiper-history-next.swiper-button-disabled.swiper-button-lock::before{
    opacity: 0.3;
  }

  .swiper-history-next::before{
    position: absolute;
    display: block;
    content: "";
    height: 14px;
    width: 14px;
    background-color: transparent;
    border-bottom: 2px solid #000;
    border-left: 2px solid #000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)rotate(225deg);
  }
  .swiper-history-prev::before{
    position: absolute;
    display: block;
    content: "";
    height: 14px;
    width: 14px;
    background-color: transparent;
    border-bottom: 2px solid #000;
    border-left: 2px solid #000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)rotate(45deg);
  }
}
