.account{
  padding: 40px 0 120px;
  @include mq(md,max){
    padding: 20px 0 60px;
  }
  &__message{
    padding: 20px;
    font-size: 14px;
    text-align: center;
    // border-bottom: solid 1px #000;
    &--complete{
      // color: #32CD32;
      // border-color: #32CD32;
      background-color: rgba(50,205,50, 0.2);
    }
  }
  &__form{
    padding: 80px 0 120px;
    max-width: 800px;
    margin:auto;
    @include mq(md,max){
      padding: 40px 0 60px;
    }
  }
  &__login{
    max-width: 480px;
    margin: 0 auto;
    .account-form__input{
      width: 100%;
    }
  }
  &__login-point{
    display: flex;
    width: 100%;
    max-width: 520px;
    justify-content: center;
    margin: 0 auto;
  }
  &__point-fix{
    display: flex;
    margin: 0 auto;
    width: 100%;
    @include mq(md,max){
      flex-direction: column;
    }
    b{
      margin: 0 12px 0 0;
      @include mq(md,max){
        text-align: center;
      }
    }
  }
  &__heading{
    margin: 0;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    @include mq(md,max){
      font-size: 20px;
    }
    &-sub{
      margin: 0;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      @include mq(md,max){
        font-size: 18px;
      }
    }
  }
  &__text{
    font-size: 14px;
    color: #3A3A3A;
    line-height: 1.66;
    margin: 0;
    @include mq(md,max){
      font-size: 12px;
    }
  }
  &-form{
    &__property{
      background-color: #eee;
      padding: 10px 40px;
      word-break: break-word;
      @include mq(md,max){
        padding: 10px 20px;
      }
      li {
        margin: 10px 0;
        font-size: 14px;
        span {
          margin: 0 1em 0 0;
          @include mq(md,max){
            display: block;
          }
        }
        @include mq(md,max){
          margin: 15px 0;
        }
      }
    }
    &__box{
      border: solid 1px #DFE0DF;
      padding: 20px 40px;
    }
    &__tab{
      display: flex;
      margin: 0 -10px;
      @include mq(md,max){
        display: block;
        margin: 0;
      }
      li {
        width: 100%;
        margin: 0 10px;
        @include mq(md,max){
          margin: 10px 0 0;
          &:first-child{
            margin: 0;
          }
        }
        a{
          text-align: center;
          color: #333;
          font-size: 14px;
          display: block;
          padding: 10px;
          border: solid 1px #DFE0DF;
          @include mq(md,max){
            padding: 5px;
          }
          &.current{
            cursor: initial;
            pointer-events: none;
            background-color: #ECEFF5;
          }
        }
      }
    }
    &__item{
      display: flex;
      align-items: flex-start;
      font-size: 14px;
      @include mq(md,max){
        display: block;
      }
      &:first-child{
        margin: 0;
      }
      &--column{
        flex-direction: column;
      }
    }
    &__title{
      width: 25%;
      padding: 15px 0;
      font-weight: initial;
      .account-form__item--column & {
        width: 100%;
      }
      @include mq(md,max){
        width: 100%;
        padding: 0;
        margin: 10px 0;
      }
      &--confirm{
        @include mq(md,max){
          font-weight: bold;
        }
      }
    }
    &__confirm{
      width: 75%;
      padding: 15px 0;
      font-weight: initial;
      display: flex;
      align-items: center;
      .account-form__item--column & {
        width: 100%;
      }
      @include mq(md,max){
        width: 100%;
        padding: 0;
      }
    }
    &__required{
      margin: 0 0 0 10px;
      font-size: 12px;
      color: #FF7070;
    }
    &__notes{
      font-size: 12px;
      color: #A3A3A3;
      margin: 0;
      &--error{
        color: $c-error!important;
      }
    }
    &__input{
      width: 75%;
      .account-form__item--column & {
        width: 100%;
      }
      @include mq(md,max){
        width: 100%;
        padding: 0;
      }
      &-list{
        display: flex;
        align-items: center;
        margin: -5px -10px;
        &>label,&>.select,&>div,&>.input:not(.input--inline){
          width: 100%;
          margin: 5px 10px!important;
        }
        &--radio,&--checkbox{
          flex-wrap: wrap;
          label {
            display: inline-block;
          }
        }
        &--center{
          justify-content: center;
        }
      }
      .input,.textarea,.select,
      input,textarea,select{
        font-size: 14px;
        padding: 14px 20px;
        width: 100%;
        box-sizing: border-box;
        max-width: 100%;
        margin: 0;
        border: solid 1px #DFE0DF;
        border-radius: 0;
        background-color: transparent;
        &:focus{
          border: solid 1px #61A8DF;
        }
        // &.valid{
        //   border-color: $c-success!important;
        // }
        &.is-error{
          border-color: $c-error!important;
        }
        @include mq(md,max){
          padding: 10px 20px;
        }
        &[type="tel"]{
          max-width: 300px;
        }
        &:disabled{
          background-color: #F2F2F2;
        }
        &--medium{
          max-width: 200px!important;
        }
        &--small{
          max-width: 100px!important;
        }
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          -webkit-appearance: none !important;
          margin: 0 !important;
          -moz-appearance:textfield !important;
        }
      }
      .textarea{
        height: 200px;
      }
      .select{
        -webkit-appearance: none;
        appearance: none; /* デフォルトのスタイルを無効 */
        background-size: 12px;
        background-position: right 20px center;
        background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216.413%22%20height%3D%229.611%22%20viewBox%3D%220%200%2016.413%209.611%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_932%22%20data-name%3D%22%E3%83%91%E3%82%B9%20932%22%20d%3D%22M0%2C0%2C7.4%2C7.486%2C15%2C0%22%20transform%3D%22translate(0.711%200.712)%22%20fill%3D%22none%22%20stroke%3D%22%23a3a3a3%22%20stroke-width%3D%222%22%2F%3E%3C%2Fsvg%3E');
        background-repeat: no-repeat;
      }
      label {
        display: flex;
        align-items: center;
        word-break: keep-all;
        .input,.select{
          max-width: calc(100% - 35px);
          @include mq(md,max){
            padding: 10px;
            max-width: calc(100% - 20px);
            background-position: right 10px center;
            background-size: 10px;
          }
        }
      }
      .radio,.checkbox{
        width: auto!important;
        label{
          border: solid 1px #DFE0DF;
          padding: 14px 20px 14px 50px!important;
          display: inline-block!important;
          @include mq(md,max){
            padding: 10px 10px 10px 40px!important;
          }
          &:before {
            left: 20px!important;
            @include mq(md,max){
              left: 10px!important;
              top: 23px!important;
            }
          }

          &:after {
            left: 31px !important;
            top: 28px !important;
            @include mq(md,max){
              top: 24px!important;
              left: 21px!important;
            }
          }
        }
        input{
          &:checked + label {
            background-color: #ECEFF5;
          }
        }
      }
      .radio{
        label{
          &:after{
            background-color: #E60E14!important;
          }
        }
      }
      .checkbox{
        label{
          &:after {
            border-bottom: 3px solid #E60E14!important;
            border-right: 3px solid #E60E14!important;
            left: 33px !important;
            top: 26px !important;
            @include mq(md,max){
              top: 23px!important;
              left: 22px!important;
            }
          }
        }
      }
    }
  }
  &-data{
    &__header{
      padding-bottom: 40px;
      border-bottom: solid 1px #1C120F;
      display: flex;
      align-items: center;
      @include mq(md,max){
        padding-bottom: 10px;
        flex-wrap: wrap;
        &>*{
          margin-bottom: 10px!important;
        }
      }
    }
    &__heading{
      font-size: 32px;
      font-weight: bold;
      display: flex;
      align-items: center;
      margin: 0 auto 0 0;
      @include mq(md,max){
        font-size: 22px;
      }
      svg{
        width: auto;
        max-height: 28px;
        margin: 0 15px 0 0;
        @include mq(md,max){
          height: 20px;
          margin: 0 10px 0 0;
        }
      }
    }
  }
  &-menu{
    border: solid 1px #DFE0DF;
    border-top: 0px;
    &__item{
      display: flex;
      align-items: center;
      height: 60px;
      padding: 0 20px;
      border-top: solid 1px #DFE0DF;
      color: #000;
      text-decoration: none;
      font-weight: bold;
      position: relative;
      font-size: 14px;
      svg {
        display: block;
        margin: 0 10px 0 0;
      }
      span {
        color: #fff;
        height: 24px;
        padding: 0 15px;
        display: flex;
        align-items: center;
        background-color: #FF0000;
        border-radius: 20px;
        margin: 0 0 0 auto;
      }
      &.current{
        color: #61A8DF;
        path{
          stroke: #61A8DF;
        }
        &:before{
          content: "";
          left: 0;
          top: -1px;
          width: 6px;
          height: calc(100%  + 2px);
          background-color: #61A8DF;
          transform: translateX(-50%);
          position: absolute;
          z-index: 1;
        }
      }
    }
  }
  &-table{
    &__item{
      display: flex;
      border-top: solid 1px #DBDBDB;
      &:first-child{
        border-top: 0;
      }
    }
    &__title,&__data{
      padding: 20px 0;
    }
    &__title{
      width: 25%;
      margin: 0 20px 0 0;
      font-weight: bold;
      flex-shrink: 0;
      @include mq(md,max){
        margin: 0 10px 0 0;
      }
    }
  }
  &-message{
    padding: 17px 20px;
    border: solid 1px #3593D9;
    background-color: #E9F3FA;
    color: #3593D9;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    margin: 0;
    svg{
      height: 17px;
      width: auto;
      margin: 0 15px 0 0;
    }
    &--error{
      color: #FF0000;
      border-color: #FF0000;
      background-color: #FFEDED;
    }
  }
  &-plan{
    &__item{
      display: flex;
      // align-items: flex-start;
      padding: 20px 0;
      border-top: solid 1px #DBDBDB;
      &:first-child{
        border-top: 0;
      }
      @include mq(md,max){
        display: block;
      }
    }
    &__img{
      margin: 0 20px 0 0;
      width: 200px;
      position: relative;
      flex-shrink: 0;
      img{
        width: 100%;
        display: block;
      }
      @include mq(md,max){
        width: 100%;
        margin: 0 0 20px 0;
      }
    }
    &__contents{
      margin: 0 auto 0 0;
      width: 100%;
      @include mq(md,max){
        margin: 0 0 20px 0;
      }
    }
    &__btn{
      flex-shrink: 0;
      display: flex;
      align-items: flex-end;
    }
    &__label{
      position: absolute;
      font-size: 12px;
      padding: 0px 10px;
      color: #fff;
      background-color: #FF0000;
      left: -10px;
      top: 0;
      @include mq(md,max){
        font-size: 14px;
        padding: 3px 10px;
      }
    }
  }
  &-card{
    &__item{
      display: flex;
      align-items: center;
      padding: 40px 0;
      border-top: solid 1px #DBDBDB;
      &:first-child{
        border-top: 0;
      }
      @include mq(md,max){
        display: block;
      }
      .checkbox{
        margin: 0 35px 0 0;
        flex-shrink: 0;
        @include mq(md,max){
          margin: 0 0 10px 0;
        }
      }
      label{
        padding-left: 24px!important;
      }
    }
    &__data{
      margin-right: auto;
      dl{
        display: flex;
        margin: 10px 0 0;
        &:first-child{
          margin: 0;
        }
      }
      dt{
        width: 160px;
        font-weight: bold;
      }
    }
    &__btns{
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include mq(md,max){
        flex-direction: row;
      }
    }
  }
  &-application{
    &__header{
      display: flex;
      align-items: flex-start;
      @include mq(md,max){
        display: block;
      }
      &--cancel{
        padding: 40px 0;
        border-top:  solid 1px #DFE0DF;
        border-bottom:  solid 1px #DFE0DF;
      }
    }
    &__img{
      width:220px;
      margin: 0 40px 0 0;
      img{
        width: 100%;
        display: block;
      }
      @include mq(md,max){
        width: 100%;
        margin: 0 0 20px 0;
      }
    }
    &__btns{
      display: flex;
      align-items: center;
      padding-top: 20px;
      margin-top: 20px;
      border-top: solid 1px #DFE0DF;
      justify-content: flex-end;
      @include mq(md,max){
        display: block;
        text-align: right;
      }
      .text-link{
        margin: 0 auto 0 0;
      }
    }
    &__price{
      display: flex;
      align-items: center;
      margin: 0;
      @include mq(md,max){
        justify-content: flex-end;
      }
      .text-right & {
        justify-content: flex-end;
      }
      &__heading{
        font-size: 14px;
        font-weight: bold;
        margin: 0 20px 0 0;
      }
      &__number{
        font-size: 20px;
        font-weight: bold;
        &:after{
          content: "円";
          font-size: 16px;
          font-weight: initial;
        }
      }
    }
    &__data{
      padding-top: 20px;
      margin-top: 20px;
      border-top: solid 1px #DFE0DF;
      .border{
        border: 0;
        th,td{
          padding: 0;
          &:before{
            content: "";
            width: 100%;
            height: 1px;
            display: block;
            background-color: #DFE0DF;
            margin: 20px 0;
          }
        }
      }
      table{
        width: 100%;
      }
      th{
        font-weight: initial;
        padding: 3px 20px 3px 0;
        width: 60%;
        @include mq(md,max){
          width: auto;
          padding: 3px 10px 3px 0;
        }
      }
      td{
        padding: 3px 20px 3px 0;
        @include mq(md,max){
          word-break: keep-all;
          white-space: nowrap;
        }
        &:last-child{
          padding-right: 0;
        }
      }
    }
  }
  &-contact{
    position: fixed;
    right: 40px;
    bottom: 40px;
    z-index: 1000;
    @include mq(md,max){
      right: 20px;
      bottom: 20px;
    }
    &__btn{
      width: 90px;
      height: 90px;
      border: solid 1px #3593D9;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 90px;
      background-color: #fff;
      box-shadow: 0 3px 6px rgba(0,0,0,0.16);
      position: relative;
      cursor: pointer;
      @include mq(md,max){
        width: 70px;
        height: 70px;
        svg{
          width: 35px;
        }
      }
      .chatOpen &{
        display: none;
      }
      span{
        position: absolute;
        right: 0;
        top: 6px;
        height: 20px;
        width: 20px;
        background-color: #FF0000;
        border-radius: 20px;
      }
    }
  }
  &-chat{
    width: calc(100vw - 40px);
    max-width: 520px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16);
    background-color: #fff;
    padding-bottom: 6px;
    max-height: calc(100vh - 80px);
    overflow-y: scroll;
    display: none;
    .chatOpen &{
      display: block;
    }
    &__header{
      background-color: #E9F3FA;
      padding: 0 20px;
      height: 60px;
      border-bottom: solid 1px #61A8DF;
      display: flex;
      align-items: center;
      color: #61A8DF;
      font-weight: bold;
      .btn{
        height: 29px;
        font-size: 12px;
        min-width: auto;
        margin: 0 0 0 auto;
        padding: 0 25px 0 18px;
      }
    }
    &__contents{
      height: 480px;
      padding: 20px 10px 10px;
      overflow-y: scroll;
    }
    &__logo{
      display: block;
      width: auto;
      margin: 0 auto 40px auto;
    }
    &__message{
      display: flex;
      position: relative;
      align-items: flex-start;
      margin: 0 0 25px 0;
      justify-content: flex-end;
      &--company{
        justify-content: flex-start;
        .account-chat__message__icon{
          margin: 0 10px 0 0;
          order: -1;
        }
        .account-chat__message__balloon{
          margin: 0 40px 0 0;
          background-color: #61A8DF;
          color: #fff;
          border-radius: 0px 10px 10px 10px;
          padding-top: 10px;
        }
      }
      &__icon{
        flex-shrink: 0;
        width: 30px;
        height: auto;
        margin: 0 0 0 10px;
      }
      &__date{
        position: absolute;
        bottom: calc(100% + 5px);
        font-size: 10px;
        left: 0;
        margin: 0;
        line-height: 1.4;
        color: #000;
      }
      &__balloon{
        background-color: #F2F2F2;
        position: relative;
        padding: 20px;
        font-size: 13px;
        border-radius: 10px 0px 10px 10px;
        margin: 0 0 0 10px;
        p {
          margin: 0;
        }
        span{
          display: block;
          margin: 0 0 10px;
          font-weight: bold;
          font-size: 10px;
          line-height: 1.4;
        }
      }
    }
    &__input{
      display: flex;
      padding: 20px;
      border: solid 1px #61A8DF;
      margin: 6px 6px 0;
      .textarea{
        background-color: transparent;
        width: 100%;
        margin: 0;
        font-size: 14px;
        resize: none;
        max-height: 80px;
        overflow-y: scroll;
      }
      &__icon{
        flex-shrink: 0;
        width: 17px;
        height: auto;
        margin: 0 0 0 10px;
      }
    }
  }
}

p.is-error {
  .account-form &,.account-form__input &,.account-form__box &,.account__login &{
    margin: 5px 0 0;
    color: $c-primary;
    font-size: 12px;
  }
}
