.service{
  @include mq(md,max){
    padding-bottom: 0;
    border-bottom: 0;
  }
  &-list{
    display: flex;
    justify-content: space-between;
    @include mq(md,max){
      display: block;
      padding: 0px;
    }
    &__wrap{
      position: relative;
      z-index: 0;
      &:before,&:after{
        content: "";
        z-index: 0;
        width: calc(50% - 15px);
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        // border: solid 1px #CACACA;
        // background: linear-gradient(60deg, #D82525, #FF0007);
        @include mq(md,max){
          display: none;
        }
      }
      &.service-list--large{
        &:before,&:after{
          width: 50%;
        }
      }
      &:after{
        left: auto;
        right: 0;
      }
    }
  }
  &-item{
    position: relative;
    z-index: 1;
    width: calc(50% - 15px);
    padding: 80px 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: solid 1px #CACACA;
    @include mq(md,max){
      border: none;
    }
    .service-list--large &{
      width: 50%;
      @include mq(md,max){
        width: 100%;
      }
    }
    @include mq(md,max){
      align-items: flex-start;
      width: 100%;
      padding: 40px 0px;
      // background: linear-gradient(60deg, #D82525, #FF0007);
    }
    .heading{
      @include mq(md,max){
        width: 100%;
        margin: 0 0 20px;
      }
    }
    .section &{
      .heading{
        color: #333;
        margin: 0 0 40px;
        @include mq(md,max){
          margin: 0 0 20px;
          width: 100%;
        }
        &:after{
          background-color: #333;
          @include mq(md,max){
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
    .section__text{
      color: #333;
      margin: 0 0 auto;
      @include mq(md,max){
        margin: 0 auto;
        // text-align: left;
      }
    }
    .btn{
      margin: 50px 0 0;
      @include mq(md,max){
        margin: 20px auto 0;
        min-width: 0;
        width: 100%;
        max-width: 260px;
      }
      &:hover{
        box-shadow: 0 0 0 1px #e60e14;
      }
    }
  }
}
