/**
 *  MAIN RULES
 */

*,
*::after,
*::before {
  box-sizing: border-box;
  outline: none;
}

body {
  background-color: $bgc-body;
  min-height: 100%;
  overflow-x: hidden;
  position: relative;
}

p {
  font-weight: normal;
  // margin-bottom: $mb-p;
}

img {
  max-width: 100%;
  vertical-align: middle;
  height: auto;
}

strong {
  font-weight: $fw-strong;
}

ul {
  margin-bottom: $mb-ul;
}

li {
  list-style: none;
  margin-bottom: $mb-li;
}
dt, dd {
  line-height: 1.42857143;
}
button,input,optgroup,select,textarea {
  font-family: inherit;
  line-height: inherit;
}