
/**
*  STEPPER
*
*  Markup:
*  -------
* <ul class="stepper mt-40-pc mt-20-sp">
*   <li class="stepper__item is-ended">STEP 1</li>
*   <li class="stepper__item is-active">STEP 2</li>
*   <li class="stepper__item">STEP 3</li>
* </ul>
*
*/
.stepper{
    text-align:center;
    display:flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width:100%;
    max-width: 300px;
    margin:0 auto;
    &:before{
        content:""; 
        position: absolute;
        top:50%;
        left:0;
        right:0;
        width:100%;
        height:1px;
        background: $c-light;
        z-index: 0;
    }
    &__item{
        position:relative;
        border: 1px solid $c-gray;
        background-color: $c-white;
        color: $c-gray;
        margin: 0;
        padding: 4px 20px;
        border-radius: 40px;
        font-size: 13px;
        font-weight: bold;
        
        &.is-ended{
            border: 1px solid $c-primary;
            background-color: $c-primary;
            color: $c-white;
        }
        &.is-active{
            border: 1px solid $c-primary;
            background-color: $c-white;
            color: $c-primary;
        }
    }
}