.mv{
  position: relative;
  z-index: 0;
  overflow: hidden;
  background-color: #000;
  // height: calc(var(--vh, 1vh) * 100);
  height: 100vh;
  &--lower{
    background-color: #000;
    text-align: center;
    &:after{
      display: none;
    }
  }
  &--sale{
    background-color: unset;
    text-align: center;
    height:unset!important;
    max-height: 702px;
    @include mq(md,max){
      max-height: 70vh !important;
    }
    &:after{
      display: none;
    }
  }
  &--about{
    background-color: unset;
    text-align: center;
    height:unset!important;
    max-height: 702px;
    @include mq(md,max){
      max-height: 70vh !important;
    }
    &:after{
      display: none;
    }
  }
  &__inner{
    position: relative;
    z-index: 2;
    height: 100%;
    color: #fff;
    padding: 100px 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    .mv--lower &{
      padding: 210px 0 120px;
      min-height: 43.75vw;
      @include mq(md,max){
        padding: 160px 0 100px;
      }
    }
    @include mq(md,max){
      padding: 0px;
      justify-content: flex-end;
    }
    .mv--lp &{
      padding: 100px 0;
      @include mq(md,max){
        padding: 0 20px 90px;
      }
    }
    &--sale{
      justify-content: flex-start;
      padding: 210px 0 120px!important;
      @include mq(md,max){
        padding: 18vh 0 280px!important;
        min-height: 60vh !important;
      }
    }
    &--about{
      justify-content: flex-start;
      padding: 182px 0 120px!important;
      @include mq(md,max){
        padding: 18vh 0 280px!important;
        min-height: 60vh !important;
      }
    }
  }
  &__contents{
    align-items: flex-end;
    display: flex;
    @include mq(md,max){
      width: 100%;
      display: block;
    }
  }
  &__img{
    height: 100vh;
    // height: 100dvh;
    width: 100vw;
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    background-size: cover;
    background-position: center;
    @include mq(md,max){
      background-image: none !important;
      width: 100%;
      height: 70%;
      img{
        width: 100%;
      }
    }
    .mv--lower &{
      height: 100%;
      width: 100%;
      opacity: 0.6;
    }
    
  }
  .mv--sale-img{
    opacity: 1!important;
    @include mq(md,max){
      background-image: url('/img/sale/sale-mv-sp.webp')!important;
    }
  }
  .mv--about-img{
    opacity: 1!important;
    filter: brightness(75%) contrast(100%);
    @include mq(md,max){
      background-image: url('/img/about/mv.webp')!important;
    }
  }
  &__heading{
    line-height: 1.5;
    display: inline-block;
    letter-spacing: 0.1em;
    font-size: 41px;
    margin: 0 30px 0 0;

    @include mq(lg,max){
      font-size: 2.5vw;
    }
    
    @include mq(md,max){
      margin: 0 0 10px 0;
      font-size: 20px;
      width: 100%;
    }

    .mv--lower &{
      font-size: 60px;
      text-align-last:inherit;
      text-align:inherit;
      @include mq(md,max){
        font-size: 40px;
      }
    }
    small{
      color: inherit;
      font-size: 0.66em;
      display: block;
    }
    .mv__heading__lp-large{
      font-size: 62px;
      letter-spacing: 0.2em;
      @include mq(lg,max){
        font-size: 4vw;
      }
      @include mq(md,max){
        letter-spacing: 0em;
        font-size: 30px;
        text-align-last: justify;
        text-align:justify;
      }
    }
    &--sale{
      font-weight: 300;
      margin: 0;
      filter: drop-shadow(0 2px 16px rgba(0, 0, 0, .2));
      @include mq(md,max){
        line-height: 2.8;
        font-size: 36px !important;
      }
    }
    &--about{
      font-weight: 300;
      margin: 0;
      filter: drop-shadow(0 2px 16px rgba(0, 0, 0, .2));
      @include mq(md,max){
        line-height: 1.2;
        font-size: 32px !important;
        margin: 40px 0 0;
      }
    }
  }

  &__logo{
    display: flex;
    font-weight: bold;
    margin: 60px 0 0;
    font-size: 12px;
    align-items: center;
    &:before{
      content: "";
      width: 104px;
      height: 2px;
      background-color: #ffffff;
      margin: 0 34px 0 0;
      @include mq(md,max){
        width: 50px;
        height: 1px;
        margin: 0 13px 0 0;
      }
    }
    @include mq(md,max){
      align-items: center;
      margin: 0;
      font-size: 10px;
    }
    img{
      margin: 0 0 0 28px;
      width: auto;
      height: 13px;
      @include mq(md,max){
        height: 10px;
        margin: 0 0 0 14px;
      }
    }
  }
  &__text__top {
    position: absolute;
    bottom: 100px;
  }
  &__text{
    font-size: 24px;
    margin-bottom: 15px;
    @include mq(md,max){
      font-size: 18px
    }
    .mv--lower &{
      font-size: 20px;
      line-height: 2;
      @include mq(md,max){
        font-size: 16px;
      }
    }
    &--sale{
      font-size: 40px!important;
      font-weight: 300;
      margin: 0 0 -12px;
      filter: drop-shadow(0 2px 16px rgba(0, 0, 0, .2));
      @include mq(md,max){
        font-size: 24px!important;
        line-height: 1.4!important;
      }
    }
    &--about{
      font-size: 40px!important;
      font-weight: 300;
      margin: 0 0 -12px;
      filter: drop-shadow(0 2px 16px rgba(0, 0, 0, .2));
      @include mq(md,max){
        font-size: 20px!important;
        line-height: 1.4!important;
      }
    }
    &--sub{
      font-size: 20px !important;
      font-weight: normal;
      margin: 100px 0 0;
      @include mq(md,max){
        font-size: 16px!important;
        line-height: 1.4!important;
      }
    }
  }
  &__lp-button{
    &:hover{
      border: solid 1px #fff;
    }
  }
  &__btn{
    &__wrap{
      margin: 0 0 0 auto;
      width: auto;
      @include mq(md,max){
        margin: 40px 0 0;
      }
    }
    &__text{
      font-size: 28px;
      line-height: 1.5;
      font-weight: bold;
      @include mq(md,max){
        font-size: 16px;
        letter-spacing: 0.1em;
      }
      span{
        display: none;
        @include mq(md,max){
          display: inline-block;
        }
      }
    }
    &:hover{
      box-shadow: 0 0 0 #000;
    }
    width: auto;
    @include mq(md,max){
      font-weight: bold;
      font-size: 17px;
      flex-direction: column;
      height: 80px;
      padding: 0 80px;
      z-index: 2;
      position:absolute;
      bottom: 20px;
      left:20px;
      width:calc(100% - 40px);
      height: 63px;
      padding: 0;
    }

    span{
      font-size: 14px;
      font-weight: initial;
      @include mq(md,max){
        margin: 3px 0 0;
        font-size: 12px;
        &:first-child{
          margin: 0;
        }
      }
    }
  }
}
