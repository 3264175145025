/**
 *  FLOATS
 */

.fleft {
  float: left;
}

.fright {
  float: right;
}

.clearfix {
  ::after {
    clear: both;
    content: '';
    display: table;
  }
}

/**
 *  MARGINS
 */

.m-xsmall {
  margin: $m-xsmall;
}

.mb-xsmall {
  margin-bottom: $m-xsmall;
}

.m-small {
  margin: $m-small;
}

.mb-small {
  margin-bottom: $m-small;
}

.m-medium {
  margin: $m-medium;
}

.mb-medium {
  margin-bottom: $m-medium;
}

.m-big {
  margin: $m-big;
}

.mb-big {
  margin-bottom: $m-big;
}

.m-huge {
  margin: $m-huge;
}

.mb-huge {
  margin-bottom: $m-huge;
}

.m-none {
  margin: 0 !important;
}

/**
 *  PADDINGS
 */
.p-small {
  padding: $p-small;
}

.pb-small {
  padding-bottom: $p-small;
}

.p-medium {
  padding: $p-medium;
}

.pb-medium {
  padding-bottom: $p-medium;
}

.p-big {
  padding: $p-big;
}

.pb-big {
  padding-bottom: $p-big;
}

.p-huge {
  padding: $p-huge;
}

.pb-huge {
  padding-bottom: $p-huge;
}

/**
 *  OTHERS
 */

.no-wrap {
  white-space: nowrap;
}

.overflow-hidden {
  overflow: hidden;
}

.opacity-low {
  opacity: .5;
}

.rounded-corners {
  border-radius: $bdr-rounded-corners;
}

.rounded {
  border-radius: 100%;
}
