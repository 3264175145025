.filter{
  &-freeword{
    background-color: #fff;
    &__item{
      display: flex;
      padding: 20px;
      position: relative;
      color:#333333;
      &:hover{
        background-color: #61A8DF;
        color:#fff;
        path{
          stroke: #fff;
        }
      }
    }
    &__text{
      margin: 0;
      font-size: 16px;
    }
    &__icon{
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      &+.filter-freeword__text{
        padding-left: 40px;
      }
    }
  }
  &-list{
    max-height: 530px;
    overflow-y: scroll;
    &__item{
      display: flex;
      align-items: center;
      border-bottom: solid 1px #DBDBDB;
      padding: 10px 0;
      &:last-child{
        border-bottom: 0;
      }
    }
    &__icon{
      width: 54px;
      height: 54px;
      border-radius: 50px;
      border: solid 1px #DBDBDB;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 10px 0 0;
    }
    &__logo{
      width: 68px;
      height: 54px;
      margin: 0 20px 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        display: block;
        width: 100%;
        height: auto;
      }
    }
    &__title{
      margin: 0 auto 0 0;
      font-size: 16px;
      span{
        opacity: 0.4;
        margin: 0 10px 0 0;
      }
    }
    &__number{
      margin: 0;
      font-size: 16px;
      span{
        color: #579ED7;
        font-weight: bold;
      }
    }
  }
  &-keyword{
    // padding-bottom: 30px;
    &__heading{
      color: #333333;
      font-weight: bold;
      font-size: 12px;
      margin: 20px 0;
    }
  }
}
