.top{
  &-concierges{
    max-width: 1180px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    @include mq(md,max){
      // overflow-x: scroll;
      display: block;
      width: calc(100vw);
      margin: 0 -20px;
      padding: 0 20px;
    }
    &__dsc {
      word-break: keep-all;
    }
    &__text {
      margin-top: 20px;
      font-weight: bold;
      @include mq(md,max) {
        margin-top: 10px;
      }
    }
    &__item{
      width: calc((100% - 110px) / 3);
      position: relative;
      @include mq(md,max){
        width: 100%;
        flex-shrink: 0;
        margin: 0 20px 0 0;
        padding-bottom: 30px;
        &:last-child{
          margin: 0;
        }
      }
      &-img {
        width: 100%;
      }
      &-point {
        position: absolute;
        top: -29px;
        left: -29px;
        width: 58px;
        height: 58px;
        @include mq(md,max) {
          width: 94px;
          height: 22px;
          position:  initial;
          text-align: center;
          margin: 0 auto;
          margin-top: -10px;
        }
      }
      &-kana{
        font-size: 14px;
        margin: 20px 0 0;
        color: #343D55;
        @include mq(md,max){
          font-size: 10px;
          margin: 6px 0 0;
          line-height: 1.2;
          letter-spacing: 0.2em;
        }
      }
      &-name{
        font-size: 32px;
        line-height: 1.5;
        color: #343D55;
        @include mq(md,max){
          font-size: 16px;
          line-height: 1.4;
        }
      }
      &-biography{
        font-size: 14px;
        letter-spacing: 0;
        @include mq(md,max){
          font-size: 12px;
        }
        p{
          margin: 10px 0 0;
          @include mq(md,max){
            margin: 4px 0 0;
            line-height: 1.4;
          }
          &:first-child{
            margin: 20px 0 0;
            @include mq(md,max){
              margin: 10px 0 0;
            }
            &:before{
              content: "";
              display: block;
              width: 60px;
              height: 1px;
              background-color: #343D55;
              margin: 0 0 20px;
              @include mq(md,max){
                margin: 0 0 5px;
                transform: translateX(-25%) scale(0.5);
              }
            }
          }
        }
      }
    }
  }
  &-links{
    text-align: center;
    @include mq(md,max){
      text-align: left;
    }
    &__item{
      display: flex;
      justify-content: space-between;
      margin: 120px auto 0;
      max-width: 1180px;
      @include mq(md,max){
        margin: 40px 0 0 -20px;
        display: block;
        width: calc(100% + 20px);
      }
      &:nth-child(even){
        flex-direction: row-reverse;
      }
      .heading{
        @include mq(md,max){
          padding: 0 0 10px;
          width: 100%;
          &:after{
            left: 0;
            transform: translateX(0);
          }
        }
      }
    }
    &__img{
      width: calc(45% - 15px);
      background-size: cover;
      background-position: center;
      min-height: 400px;
      @include mq(md,max){
        width: 100%;
        min-height: 167px;
        margin: 0 0 20px;
      }
    }
    &__contents{
      width: calc(50% - 15px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @include mq(md,max){
        width: 100%;
        padding-left: 40px;
        align-items: flex-start;
      }
      @include mq(md,min){
        .heading{
          &:after{
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
    .btn{
      min-width: 0;
      width: 100%;
      max-width: 278px;
    }
  }
  &-vision{
    text-align: center;
    background: linear-gradient(60deg, #D82525, #FF0007);
    @include mq(md,max){
      padding: 60px 0 50px;
    }
    &__inner{
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }
    &__logo{
      max-width: 300px;
      margin: 0 auto;
      @include mq(md,max){
        max-width: 150px;
      }
    }
    &__heading{
      font-size: 32px;
      padding: 20px 0 20px 0.4em;
      position: relative;
      color: #fff;
      margin: 80px 0 0;
      letter-spacing: 0.4em;
      @include mq(md,max){
        font-size: 16px;
        margin: 40px 0 0;
        padding: 15px 0 15px 0.4em;
      }
      &:before,&:after{
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        height: 1px;
        width: 40px;
        background-color: #fff;
        @include mq(md,max){
          transform: translateX(-50%) scale(0.5);
        }
      }
      &:after{
        top: auto;
        bottom: 0;
      }
    }
    &__text{
      font-size: 20px;
      line-height: 2;
      color: #fff;
      margin: 80px 0 0;
      @include mq(md,max){
        font-size: 14px;
        margin: 40px 0 0;
        line-height: 1.8;
      }
    }
  }
  &-reason{
    padding: 60px 0 0;
    margin: 60px 0 0;
    position: relative;
    text-align: center;
    @include mq(md,max){
      padding: 30px 0 0;
      margin: 30px 0 0;
    }
    &:before{
      content: "";
      width: 120px;
      position: absolute;
      height: 1px;
      background-color: #343D55;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      @include mq(md,max){
        width: 60px;
      }
    }
    &__text{
      font-size: 18px;
      line-height: 2;
      @include mq(md,max){
        font-size: 12px;
      }
    }
    &__list{
      letter-spacing: 0.1em;
      font-size: 24px;
      font-weight: bold;
      margin: 30px 0 0;
      @include mq(md,max){
        font-size: 16px;
        margin: 20px 0 0;
      }
      li{
        margin: 10px 0 0;
        @include mq(md,max){
          margin: 15px 0 0;
        }
        &:first-child{
          margin: 0;
        }
      }
    }
    &__notes{
      font-size: 14px;
      margin: 30px 0 0;
      @include mq(md,max){
        text-align: left;
        font-size: 10px;
        margin: 20px 0 0;
        line-height: 1.5;
      }
    }
  }
}
.scrolldown{
position:absolute;
bottom:5%;
right:5%;
animation: arrowmove 1s ease-in-out infinite;
}
@keyframes arrowmove{
    0%{bottom:12%;}50%{bottom:14%;}100%{bottom:12%;}
}
.scrolldown:before {
  content: "";
  position: absolute;
  bottom: 100px;
  right: -6px;
  width: 1px;
  height: 20px;
  background: #eee;
  transform: skewX(-31deg);
  @include mq(md,max){
    bottom: 40px;
    // right: -6px;
  }
}
.scrolldown:after{
content:"";
position: absolute;
bottom:100px;
right:0;
width:1px;
height: 70px;
background:#eee;
@include mq(md,max){
  bottom:40px;
  // right:10px;
}
}


#global-nav {
	background: #fff;
	border-bottom: 1px solid #16244e;
	border-top: 1px solid #16244e;
	width: 100%;
}

#global-nav.m_fixed {
	left: 0;
	position: fixed;
	top: 0;
}

#global-nav .inner {
	padding-bottom: 0;
	padding-top: 0;
	margin: 0 auto;
  max-width: 100%;
  padding: 80px 0;
  width: 768px;
}

.global-list {
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
}

.global-item {
	border-left: 1px solid #fff;
	width: 20%;
}

.global-item:last-child {
	border-right: 1px solid #fff;
	margin-right: 0;
}

.global-item a {
	background: #16244e;
	color: #fff;
	display: block;
	line-height: 50px;
	text-align: center;
	text-decoration: none;
}
