/**
 *  ALIGNERS
 */

// container
.flex {
  display: flex;

  &--spaceBetween {
    justify-content: space-between;
    width: 100%;
  }

  &--spaceAround {
    justify-content: space-around;
    width: 100%;
  }

  &--centerVertical {
    align-items: center;
  }

  &--centerHoritzontal {
    justify-content: center;
  }

  &--contentStart {
    justify-content: flex-start;
  }

  &--contentEnd {
    justify-content: flex-end;
  }

  // item
  &--itemTop {
    align-self: flex-start;
  }

  &--itemBottom {
    align-self: flex-end;
  }

  &--grow {
    flex-grow: 1;
  }
}
