.terms{
  max-width: 800px;
  margin: 0 auto;
  &__contents{
    font-size: 14px;
    &>div{
      margin: 1em 0 1em;
    }
    &>*{
      &:first-child{
        margin-top: 0;
      }
      &:last-child{
        margin-bottom: 0;
      }
    }
    h2{
      font-size: 18px;
      font-weight: bold;
      margin: 3em 0 2em;
      line-height: 1.4;
    }
    h3{
      font-size: 16px;
      font-weight: bold;
      margin: 2em 0 1em;
      line-height: 1.4;
    }
    p{
      margin: 1em 0 1em;
      font-size: 14px;
      line-height: 1.7;
    }
    a{
      text-decoration: underline;
      word-break: break-all;
    }
    ul{
      font-size: 14px;
      padding-left: 2em;
      list-style-type: disc;
      >li{
        list-style-type: disc;
      }
    }
    ol{
      font-size: 14px;
      padding-left: 2em;
      list-style-type: decimal;
      >li{
        list-style-type: decimal;
      }
    }
  }
  &-sns{
    display: flex;
    align-items: center;
    &__btn{
      margin: 0 1em 0 0;
      display: flex;
      align-items: center;
      img{
        height:20px;
        width:auto;
      }
    }
  }
}
.legal-list {
  list-style: none!important;
  padding-left: 1.8em;
  text-indent: -1.3em;
}