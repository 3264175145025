.faq{
  &-item{
    @include mq(md,max){
      font-size: 14px;
    }
    &:not(:first-child){
      padding: 40px 0 0;
      margin: 40px 0 0;
      border-top: solid 1px #CACACA;
      @include mq(md,max){
        padding: 20px 0 0;
        margin: 20px 0 0;
      }
    }
    &__question,&__anser{
      padding: 0 0 0 40px;
      position: relative;
      @include mq(md,max){
        padding: 0 0 0 25px;
      }
      &:before{
        content: "A";
        left: 0;
        top: 0;
        position: absolute;
        font-size: 20px;
        font-weight: bold;
        @include mq(md,max){
          font-size: 14px;
        }
      }
    }
    &__question{
      font-weight: bold;
      margin: 0 0 30px;
      @include mq(md,max){
        margin: 0 0 12px;
      }
      &:before{
        content: "Q";
      }
    }
  }
}
h2 {
  font-size: 18px;
  font-weight: bold;
  margin: 3em 0 2em;
  line-height: 1.4;
}