.pagination{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  flex-wrap: wrap;
  &__wrap{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    flex-wrap: wrap;
  }
  &__btn{
    width: 54px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    background-color:#ECEFF5;
    border-radius: 50%;
    opacity: 0.5;
    @include mq(lg,max){
      width: 40px;
      height: 40px;
      font-size: 14px;
    }
    &[href]{
      opacity: 1;
      background-color: transparent;
      &:hover{
        background-color:#ECEFF5;
      }
    }
    &--next ,&--prev {
      background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%229.002%22%20height%3D%2215.744%22%20viewBox%3D%220%200%209.002%2015.744%22%3E%20%3Cpath%20id%3D%22Icon_ionic-ios-arrow-forward%22%20data-name%3D%22Icon%20ionic-ios-arrow-forward%22%20d%3D%22M13.786%2C12%2C7.828%2C6.047a1.12%2C1.12%2C0%2C0%2C1%2C0-1.589%2C1.135%2C1.135%2C0%2C0%2C1%2C1.594%2C0l6.75%2C6.745a1.123%2C1.123%2C0%2C0%2C1%2C.033%2C1.552L9.427%2C19.547a1.125%2C1.125%2C0%2C1%2C1-1.594-1.589Z%22%20transform%3D%22translate(16.5%2019.875)%20rotate(180)%22%20fill%3D%22%23333%22%2F%3E%3C%2Fsvg%3E');
      background-size: 9px;
      background-repeat: no-repeat;
      @include mq(md,max){
        background-size: 6px;
      }
      background-position: center;
    }
    &--next {
      transform: rotate(180deg);
    }
    &--points{
      background-color: transparent;
    }
  }
}
