.property{
  &-detail{
    .property-item__contents{
      width: 35%;
      margin: 0 0 0 90px;
      @include mq(md,max){
        width: 100%;
        margin: 0;
      }
    }
    .property-item{
      margin: 16px 0 0;
      @include mq(md,max){
        margin: 0;
        padding: 0;
        border: 0;
      }
    }
    &__header{
      display: flex;
      align-items: flex-start;
      @include mq(md,max){
        display: block;
      }
      .property-item__label{
        @include mq(md,min){
          font-size: 16px;
          padding: 10px 20px;
        }
      }
      .property-item__status{
        margin: 0 0 0 auto;
        @include mq(md,max){
          margin: 5px 0 0;
        }
      }
    }
    &__heading{
      font-size: 32px;
      font-weight: bold;
      margin: 10px 0 0;
      line-height: 1.46;
      @include mq(md,max){
        font-size: 20px;
        margin: 5px 0 0;
      }
    }
    &__subheading{
      position: relative;
      text-align: center;
      &:after{
        content: "";
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: 1px;
        background-color: #343D55;
        position: absolute;
        opacity: 0.5;
      }
      p{
        background-color: #fff;
        display: inline-block;
        z-index: 1;
        position: relative;
        padding: 0 5px;
        font-size: 20px;
        font-weight: bold;
        color: #343D55;
        @include mq(md,max){
          font-size: 14px;
        }
      }
    }
    &__description{
      color: #3B4043;
    }
    &__relation{
      background-color: #ECEFF5;
      position: relative;
      z-index: 0;
      &__wrap{
        background-color: #fff;
        max-width: 1434px;
        margin: 1px auto 0;
        padding: 60px 20px 40px;
        @include mq(md,max){
          padding: 30px 20px;
        }
      }
      .width-md{
        @include mq(md,max){
          width: 100%;
        }
      }
      &__swiper{
        position: relative;
        &__inner{
          overflow: hidden;
        }
        .swiper-button-next,.swiper-button-prev{
          width: 54px;
          height: 54px;
          position: absolute;
          left: -3%;
          top: 50%;
          transform: translate(-50%,-50%);
          z-index: 10;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 10;
          // background-color: transparent;
          cursor: pointer;
          &.swiper-button-disabled{
            pointer-events: none;
            opacity: 0.5;
          }
          @include mq(md,max){
            top: calc(100% - 10px);
            width: 30px;
            height: 30px;
            left: 15px;
          }
        }
        .swiper-button-next{
          left: calc(100% + 3%);
          transform: translate(-50%,-50%) rotate(180deg);
          @include mq(md,max){
            left: calc(100% - 15px);
          }
        }
        .property-item{
          padding: 0;
          border: 0;
          margin: 0;
          @include mq(md,max){
            display: flex;
            flex-direction: column;
            height: 100%;
            flex: 1 0 auto; /* fix IE11 */
          }
        }
      }
    }
    &__description {
      @include mq(md,max){
        font-size: 14px;
      }
    }
    &__footer{
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 10px 0;
      background-color: #fff;
      z-index: 9;
      &__inner{
        display: flex;
        align-items: center;
      }
      .btn{
        margin: 0 0 0 auto;
        @include mq(md,max){
          min-width: 0px;
        }
      }
    }
    &__sns{
      z-index: 9;
      display: flex;
      align-items: center;
      color: #3B4043;
      font-size: 10px;
      letter-spacing: 0.2em;
      @include mq(md,max){
        // position: absolute;
        // bottom: calc(100% + 20px);
        // left: 0;
        // padding: 0 20px;
        p {
          display: none;
        }
      }
      img{
        width: 44px;
        @include mq(md,max){
          width: 36px;
        }
      }
      &__item{
        margin: 0 10px 0 0;
        @include mq(md,max){
          margin: 0 5px 0 0;
        }
      }
    }
    &__login{
      padding-bottom: 100px;
      background: linear-gradient(to top, #ECEFF5, #fff 100px);
      @include mq(md,max){
        padding-bottom: 50px;
        background: linear-gradient(to top, #ECEFF5, #fff 50px);
      }
      &__inner{
        display: flex;
        @include mq(md,max){
          display: block;
        }
      }
      &__item{
        width: 50%;
        border-right: solid 1px rgba(51, 51, 51, 0.2);
        padding: 0 20px;
        text-align: center;
        @include mq(md,max){
          width: 100%;
          border-right: 0;
          border-bottom: solid 1px rgba(51, 51, 51, 0.2);
          padding: 0 0 20px;
        }
        &:last-child{
          border-color: transparent;
          @include mq(md,max){
            padding: 20px 0 0;
          }
        }
        .btn--large{
          @include mq(md,max){
            min-width: 0px;
          }
        }
      }
      &__heading{
        font-size: 24px;
        line-height: 1.6;
        @include mq(md,max){
          font-size: 16px;
        }
      }
    }
  }
  &-item{
    margin: 60px 0 0;
    position: relative;
    @include mq(md,max){
      margin: 30px 0 0;
      padding: 30px 0 0;
      border-top: solid 1px #CACACA;
    }
    &:first-child{
      margin: 0;
      @include mq(md,max){
        padding: 0;
        border: 0;
      }
    }
    .width-md{
      @include mq(md,min){
        width: calc(100% - 220px);
      }
    }
    &__check{
      position: absolute;
      left: 0;
      top: 0;
      @include mq(md,max){
        position: initial;
        margin: 0 8px 0 0;
      }
      .checkbox{
        margin: 0;
        input + label{
          width: 92px;
          height: 92px;
          padding: 0;
          background: #ECEFF5;
          border-radius: 50px;
          @include mq(md,max){
            width: 50px;
            height: 50px;
          }
        }
        input:checked + label{
          background: linear-gradient(60deg,#D82525, #FF0007);
        }
        input + label:before{
          width: 30px;
          height: 30px;
          transform: translate(-50%,-50%);
          top: 50%;
          left: 50%;
          margin: 0;
          @include mq(md,max){
            width: 20px;
            height: 20px;
          }
        }
        input[type=checkbox]:checked + label:before{
          background-color: transparent;
          border: transparent;
          background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2228.243%22%20height%3D%2220.121%22%20viewBox%3D%220%200%2028.243%2020.121%22%3E%20%3Cpath%20id%3D%22Icon_feather-check%22%20data-name%3D%22Icon%20feather-check%22%20d%3D%22M30%2C9%2C13.5%2C25.5%2C6%2C18%22%20transform%3D%22translate(-3.879%20-6.879)%22%20fill%3D%22none%22%20stroke%3D%22%23fff%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%223%22%2F%3E%3C%2Fsvg%3E');
          background-repeat: no-repeat;
          background-size: 24px;
          background-position: center;
          @include mq(md,max){
            background-size: 16px;
          }
        }
        input[type=checkbox] + label:after{
          display: none;
        }
      }
    }
    &__inner{
      display: flex;
      align-items: flex-start;
      @include mq(md,max){
        display: block;
      }
    }
    &__header{
      display: flex;
      align-items: flex-start;
      margin: 0 0 20px;
      @include mq(md,max){
        margin: 0 0 5px;
        display: block;
      }
    }
    &__label{
      padding: 2px 20px;
      border: solid 1px #CCD5E5;
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      flex-shrink: 0;
      margin: 0 10px 0 0;
      @include mq(md,max){
        letter-spacing: 0;
        padding: 5px 10px;
        font-size: 11px;
        background-color: #ECEFF5;
        margin: 0 5px 0 0;
        line-height: 1;
      }
      span {
        font-weight: bold;
        margin: 0 0 0 10px;
        letter-spacing: 0;
        word-break: break-all;
        @include mq(md,max){
          margin: 0 0 0 5px;
        }
      }
    }
    &__number {
      min-width: 40px;
    }
    &__heading{
      font-size: 18px;
      font-weight: bold;
      line-height: 1.5;
      @include mq(md,max){
        font-size: 14px;
        font-weight: initial;
        margin: 10px 0;
      }
    }
    &__notes{
      font-size: 12px;
      line-height: 1.4;
      @include mq(md,max){
        font-size: 10px;
      }
    }
    &__gallery {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      width: 100%;
      min-height: 380px;
      gap: 5px 5px;
      overflow: hidden;
      max-height: 410px;
      @include mq(md,max){
        min-height: 0;
        margin-bottom: 10px;
        height: calc((100vw - 40px) * 0.38770053475);
      }
      &--1{
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-template-areas:
        "one one";
      }
      &--2{
        grid-template-columns: 1fr 2fr;
        grid-template-rows: 1fr 2fr;
        grid-template-areas:
        "one two"
        "one two";
      }
      &--3{
        grid-template-columns: 3fr 2fr;
        grid-template-rows: 3fr 2fr;
        grid-template-areas:
        "one two"
        "one three";
        @include mq(md,max){
          grid-template-rows: 2fr 2fr;
        }
      }
      &-img{
        display: block;
        width:100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
        background-size: cover;
        background-position: center;
        &:nth-child(1) { grid-area: one; }
        &:nth-child(2) { grid-area: two; }
        &:nth-child(3) { grid-area: three; }
      }
    }
    
    &__slider{
      width: 100%;
      overflow: hidden;
      position: relative;
      border: solid 1px #ddd;
      background: #fff;
      aspect-ratio: 16/9;
      &-figure{
        width: 100%!important;
        &:hover{
          opacity:1;
          img{
            // opacity:0.6;
          }
        }
      }
      &-img{
        height: 100%;
        width: auto;
        margin: 0 auto;
        display: block;
        object-fit: contain;
        // @include mq(md,max) {
        //   height: 25vh;
        // }
      }
      &-pagination{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 25px;
        margin: auto;
        display: inline-flex;
        justify-content: center;
        z-index: 1;
        @include mq(md,max){
          bottom: 5px;
        }
      }
      &-zoom {
        display: block;
        position: absolute;
        right: 20px;
        bottom: 20px;
        z-index: 5;
        width: 33px;
        height: 33px;
        outline: none;
        &:focus{ outline: none; }
        @include mq(md,max){
          bottom: 10px;
          right: 10px;
        }
      }
    }
    &__img{
      width: 60%;
      background-size: cover;
      background-position: center;
      min-height: 400px;
      position: relative;
      @include mq(md,max){
        width: 100%;
        margin: 5px 0 0;
        min-height: 0;
        display: block;
      }
      &__badge{
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 22%;
      }
    }
    &__contents{
      width: 40%;
      flex-shrink: 0;
      margin: 0 0 0 30px;
      @include mq(md,max){
        width: 100%;
        margin: 0;
      }
    }
    &__status{
      display: flex;
      flex-wrap: wrap;
      margin: 0 -5px -5px;
      @include mq(md,max){
        margin: 0 -2px -2px;
      }
      &-item{
        font-size: 16px;
        padding: 10px;
        border: solid 1px #CCD5E5;
        color: $c-dark;
        margin: 0 5px 5px;
        @include mq(md,max){
          font-size: 13px;
          padding: 3px 5px;
          margin: 0 2px 2px;
          line-height: 1.2;
        }
        &--noborder{
          border: 0px;
        }
        span{
          font-size: 24px;
          font-weight: bold;
          line-height: 16px;
          @include mq(md,max){
            font-size: 13px;
            line-height: 1.2;
          }
        }
      }
    }
    &__price{
      display: flex;
      flex-wrap: wrap;
      margin: 0 0 -20px ;
      &-item{
        width: 50%;
        margin: 0 0 20px;
      }
      &-number{
        font-size: 18px;
        font-weight: bold;
        @include mq(md,max){
          font-size: 10px;
        }
        span{
          font-size: 44px;
          @include mq(md,max){
            font-size: 22px;
          }
        }
        .--narrow{
          font-size: 44px;
          font-weight: normal;
          @include mq(md,max){
            font-size: 22px;
          }
        }
        .--contact{
          font-size: 20px;
          font-weight: bold;
          margin: 11px 0 0;
          display: inline-block;
          @include mq(md,max){
            font-size: 18px;
            margin: 0;
          }
        }
      }
      &-notes{
        font-size: 14px;
        line-height: 1.5;
        margin: 10px 0 0;
        @include mq(md,max){
          margin:  0;
          font-size: 11px;
        }
      }
    }
    &__data{
      display: flex;
      flex-wrap: wrap;
      margin: 0 0 -20px;
      @include mq(md,max){
        margin:  0 -4px -8px;
      }
      &-item{
        width: 30%;
        margin: 0 0 20px;
        padding-right: 15px;
        @include mq(md,max){
          padding-right: 0;
          margin:  0 4px 8px;
          width: calc(30% - 8px);
        }
      }
      &-heading{
        font-size: 16px;
        font-weight: bold;
        @include mq(md,max){
          font-size: 14px;
        }
        &--large{
          font-size: 20px;
          @include mq(md,max){
            font-size: 14px;
          }
        }
      }
      &-notes{
        font-size: 12px;
        line-height: 1.5;
        @include mq(md,max){
          font-size: 11px;
        }
      }
    }
    &__tag{
      display: flex;
      flex-wrap: wrap;
      margin: 0 -5px -5px;
      @include mq(md,max){
        margin: 0 -2px -2px;
      }
      &-item{
        margin: 0 5px 5px;
        padding: 2px 10px;
        border: solid 1px #CCD5E5;
        font-size: 13px;
        color: $c-dark;
        @include mq(md,max){
          padding: 3px 5px;
          margin: 0 2px 2px;
          line-height: 1.2;
        }
      }
    }
    &__btns{
      display: flex;
      .btn:not(.btn--gray){
        width: 100%;
        margin: 0 1px 0 0;
        @include mq(md,max){
          width: calc(50% - 8px);
          padding: 0;
          min-width: 0px;
        }
      }
      .btn--gray{
        min-width: 0px;
        padding: 0 40px;
        margin: 0 10px 0 0;
        flex-shrink: 0;
        @include mq(md,max){
          width: calc(50% - 4px);
          margin: 0 8px 0 0;
          padding: 0;
          flex-shrink: 1;
        }
      }
    }
  }
  &-options{
    &__icons{
      display: flex;
      justify-content: center;
      margin: 30px 0 0;
      @include mq(md,max){
        margin: 20px 0 0;
      }
      &__item{
        width: calc(100% / 3);
        max-width: 150px;
        position: relative;
        text-align: center;
        &:after{
          content: "";
          height: 40px;
          width: 1px;
          background-color: #343D55;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
          opacity: 0.5;
        }
        &:last-child{
          &:after{
            display: none;
          }
        }
        img{
          display: inline-block;
          height: 42px;
          width: auto;
          margin: auto;
        }
        p{
          font-size: 12px;
          font-weight: bold;
          color: #343D55;
          margin: 10px 0 0;
          letter-spacing: 0;
        }
      }
    }
    &__heading{
      font-size: 20px;
      font-weight: bold;
      background-color: #ECEFF5;
      padding: 16px 20px;
      @include mq(md,max){
        font-size: 14px;
        padding: 10px 20px;
      }
    }
    &__list{
      &__wrap{
        @include mq(md,min){
          margin: 0 -30px 0 0;
        }
        &:after{
          content: "";
          display: block;
          clear:both;
        }
      }
      margin: 30px 0 10px;
      font-size: 16px;
      @include mq(md,min){
        float: left;
        width: 50%;
        padding-right: 30px;
      }
      @include mq(md,max){
        font-size: 14px;
        margin: 20px 0 10px;
      }
      dl{
        padding: 20px 0;
        border-bottom: solid 1px #CACACA;
        display: flex;
        @include mq(md,max){
          padding: 15px 0;
        }
        dt{
          width: 40%;
          padding-right: 20px;
          font-weight: bold;
          @include mq(md,max){
            width: 30%;
            padding-right: 10px;
          }
        }
        dd{
          word-wrap: break-word;
          width: 60%;
          @include mq(md,max){
            width: 70%;
          }
        }
      }
    }
  }
}
.pagenation-disabled{
  display: none!important;
}
.js-detail-swiper-button-next-detail {
  border-top: solid 3px #000;
  border-right: solid 3px #000;
  width: 16px;
  height: 16px;
  top: 50%;
  left: 93%;
  cursor: pointer;
  transform: rotate(45deg);
  position: absolute;
  z-index: 6;
  @include mq(md,max){
    border-top: solid 2px #000;
  border-right: solid 2px #000;
  width: 12px;
  height: 12px;
  }
}
.js-detail-swiper-button-prev-detail {
  border-top: solid 3px #000;
  border-right: solid 3px #000;
  width: 16px;
  height: 16px;
  top: 50%;
  left: 4%;
  transform: rotate(225deg);
  cursor: pointer;
  position: absolute;
  z-index: 6;
  @include mq(md,max){
    border-top: solid 2px #000;
  border-right: solid 2px #000;
  width: 12px;
  height: 12px
  }
}
.swiper-button-next-detail {
  border-top: solid 3px #000;
  border-right: solid 3px #000;
  width: 16px;
  height: 16px;
  top: 10%;
  left: 93%;
  cursor: pointer;
  transform: rotate(45deg);
  @include mq(md,max){
    border-top: solid 2px #000;
  border-right: solid 2px #000;
  width: 12px;
  height: 12px;
  }
}
.swiper-button-prev-detail {
  border-top: solid 3px #000;
  border-right: solid 3px #000;
  width: 16px;
  height: 16px;
  top: 10%;
  left: 4%;
  transform: rotate(225deg);
  cursor: pointer;
  @include mq(md,max){
    border-top: solid 2px #000;
  border-right: solid 2px #000;
  width: 12px;
  height: 12px
  }
}