.swiper{
  position: relative;
  &-button-prev,&-button-next{
    left: 0;
    width: 54px;
    height: 54px;
    border-radius: 50px;
    background-color: #fff;
    transform: translateX(-50%);
    &:after{
      content: "";
      width: 16px;
      height: 16px;
      margin: 0 0 0 -3px;
      background-image: url('/img/icons/swiper-arrow.svg');
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  &-button-next{
    left: auto;
    right: 0;
    transform: translateX(50%) rotate(180deg);
  }
}
.swiper-pagination-bullet{
  @include mq(md,max){
    margin: 0 5px;
  }
}
.swiper-pagination-bullet:after{
  @include mq(md,max){
    width: 10px;
    height: 10px;
  }
}
