/**
 *  BACKGROUND
 */

.bg {
  @each $type, $color in $c-map {
    &-#{$type} {
      background-color: $color;
    }
  }
}
