.scrollFixed{
  &.sticky{
    position: absolute!important;
    top: auto!important;
    bottom: 0;
    left: 0!important;
  }
  &-wrap{
    position: relative;
    height: 100%;
  }
}
