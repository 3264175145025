.sidenav{
  height: 100%;
  width: 400px;
  padding: 20px;
  position: fixed;
  z-index: 1;
  top: 0;
  right: -400px;
  background-color: white;
  overflow-x: hidden;
  transition: right 0.5s;
  a {
    color: #000;
    text-decoration: none;
    display: block;
    transition: 0.3s;
  }
}
