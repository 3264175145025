/**
 *  BORDER
 */

.border {
  border: $bd-medium;
}

.border-bottom {
  border-bottom: $bd-medium;
}

.border-left {
  border-left: $bd-medium;
}

.border-right {
  border-right: $bd-medium;
}

.border-top {
  border-top: $bd-medium;
}
