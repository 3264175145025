.layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  // min-height: 100dvh;
  &__footer{
    margin-top:auto;
  }
}
