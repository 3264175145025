.sitemap{
  font-size: 14px;
  &-contents{
    display: flex;
    justify-content: space-between;
    @include mq(md,max){
      display: block;
      border-top: solid 1px #ddd;
    }
  }
  &-list{
    width: calc(50% - 20px);
    border-top: solid 1px #ddd;
    margin: 0;
    @include mq(md,max){
      width: 100%;
      border-top: 0;
    }
    li {
      margin: 0;
    }
    &__item{
      display: flex;
      align-items: center;
      padding: 15px 0;
      border-bottom: solid 1px #ddd;
      color: #000;
    }
    &__lower{
      a {
        &:before{
          content: "";
          width: 10px;
          height: 1px;
          background-color: #000;
          margin: 0 5px 0 0;
        }
      }
    }
  }
  a {
    &:after{
      content: "";
      width: 6px;
      height: 6px;
      border-right: solid 1px #000;
      border-bottom: solid 1px #000;
      transform: rotate(-45deg);
      margin: 0 0 0 auto;
    }
  }
}
