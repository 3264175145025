button, html input[type="button"], input[type="reset"], input[type="submit"]{
  -webkit-appearance: button;
  cursor: pointer;
}

.btn{
  align-items: center;
  background: linear-gradient(60deg,#D82525, #FF0007);
  border-radius: 50px;
  box-sizing: border-box;
  border: 1px solid transparent;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  padding: 0 30px;
  cursor: pointer;
  outline: none;
  appearance: none;
  letter-spacing: 0.1em;
  height: 56px;
  min-width: 214px;
  touch-action: manipulation;
  white-space: nowrap;
  line-height: 1.42857143;
  user-select: none;

  // margin-bottom: 0;
  // font-weight: normal;
  // vertical-align: middle;
  // cursor: pointer;
  // background-image: none;
  // padding: 10px 18px;
  // font-size: 15px;
  // border-radius: 0;
  @include mq(md,max){
    font-size: 14px;
    height: 50px;
  }
  &:hover,&:focus{
    box-shadow: none;
    outline: none;
    box-shadow: 0 0 0 1px $c-primary;
    background: #fff;
    color: $c-primary;
    opacity: 1;
    outline-offset: 0px;
    outline: 0px solid transparent!important;
    path,g{
      stroke: $c-primary;
    }
  }
  &--border{
    background: linear-gradient(60deg,#D82525, #FF0007);
    color: #fff;
    box-sizing: border-box;
    &:hover{
      color: $c-primary;
      box-shadow: 0 0 0 1px $c-primary;
      background: #fff;
      opacity: 1;
      box-shadow: 0 0 0 1px #e60e14!important;
      path{
        stroke: #fff;
      }
    }
  }
  &--close {
    &:hover,&:focus{
      color: #000;
      box-shadow: 0 0 0 1px #eceff5;
      path,g{
        stroke: $c-black;
      }
    }
  }
  &--comingsoon{
    position: relative;
    cursor: initial;
    &:hover{
      color: transparent;
      &:before{
        content: "Coming Soon";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        font-size: 16px;
        color: #D82525;
        @include mq(md,max){
          font-size: 14px;
        }
      }
    }
    &.btn--border{
      &:hover{
        &:before{
          color: #fff;
        }
      }
    }
  }
  &__icon{
    margin: 0 15px 0 0;
  }
  &-arrow{
    border-radius: 50px;
    height: 46px;
    min-width: 136px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: #579ED7;
    font-size: 14px;
    padding: 0 40px;
    &:after{
      content: "";
      width: 14px;
      height: 14px;
      margin: 0 0 0 5px;
      background-image: url('/img/icons/btn-arrow.svg');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &--gray{
    background: #ECEFF5;
    box-shadow: 0 0 0 1px #ECEFF5;
    color: #000;
    &.btn-arrow{
      &:after{
        background-image: url('/img/icons/btn-arrow-gray.svg');
      }
    }
    &:hover{
      background: #fff;
      box-shadow: 0 0 0 1px #ECEFF5!important;
      color: #000;
      path{
        stroke: #000;
      }
    }
    &.btn--border{
      background: #fff;
      &:hover{
        background: #ECEFF5;
      }
    }
  }

  &--large{
    min-width: 280px;
    padding: 0 45px;
    @include mq(md,min){
      width: 100%;
      max-width: 518px;
      font-size: 24px;
      height: 84px;
    }
  }

  &--disabled{
    background:#E6E6E6!important;
    color: #767676!important;
    box-shadow: 0 0 0 1px #E6E6E6!important;
    pointer-events: none;
    font-weight: initial;
  }

  &__smalltext {
    display: block;
    font-size: 12px;
    font-weight: initial;
  }
}
.mv--sale{
  .btn{
    @include mq(md,max){
      margin: 0px 0 0 !important;
    }
  }
}