.page{
  &-lower{
    padding:0 0 120px;
    &__heading{
      margin: 0;
      text-align: center;
      font-size: 24px;
      font-weight: bold;
    }
  }
}
