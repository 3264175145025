@charset 'UTF-8';
@use 'sass:math';

// Settings
@import 'settings/all';

// library
@import 'library/mixin';
@import 'library/swiper';

// Utils
@import 'utils/breakpoints';
@import 'utils/reset';
@import 'utils/default';
@import 'utils/background';
@import 'utils/border';
@import 'utils/flex';
@import 'utils/helpers';
@import 'utils/layout';
@import 'utils/typography';
@import 'utils/grid';
@import 'utils/margin';

// Components
@import 'components/forms';
@import 'components/stepper';
@import 'components/btn';
@import 'components/pagination';
@import 'components/modal';
@import 'components/layout';
@import 'components/nav';
@import 'components/sidenav';
@import 'components/footer';
@import 'components/swiper';
@import 'components/filter';
@import 'components/daterangepicker';
@import 'components/scrollFixed';
@import 'components/account';
@import 'components/accordion';
@import 'components/mv';
@import 'components/section';
@import 'components/width';
@import 'components/top';
@import 'components/property';
@import 'components/faq';
@import 'components/fixedLink';
@import 'components/breadcrumb';
@import 'components/terms';
@import 'components/page';
@import 'components/search';
@import 'components/heading';
@import 'components/service';
@import 'components/feature';
@import 'components/flow';
@import 'components/alternate';
@import 'components/error';
@import 'components/lp-feature';
@import 'components/company';
@import 'components/sitemap';
@import 'components/magazine';


html,body{
  width: 100%;
  overflow-x: hidden;
  letter-spacing: 0.1em;
  color: $c-dark;
  @include mq(md,max){
    letter-spacing: 0;
  }
}

a{
  color: $c-primary;
  &:hover{
    color: initial;
    text-decoration: none;
    opacity: 0.6;
  }
}

.content{
  transition: margin-right 0.5s;
  padding-top: 90px;

  @include mq(md,max){
    padding-top: 60px;
  }
  .layout__mv & {
    padding-top: 0;
  }
}

.pc{
  display: block!important;

  @include mq(md,max){
    display: none!important;
  }
}

.sp{
  display: none!important;

  @include mq(md,max){
    display: block!important;
  }
}

.btn--fixed-bottom-sp{
  position: fixed;
  bottom: 20px;
  left: 20px;
  right: 20px;
  width: calc( 100% - 110px);
  display: none;
  @include mq(md,max){
    .nav-scrolled &{
      display: flex;
    }  
  }
}