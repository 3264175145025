.flow{
  &-list{
    max-width: 946px;
    margin: auto;
    counter-reset: flowNumber 0;
  }
  &-item{
    min-height: 216px;
    padding: 0 40px 0 78px;
    background-color: #F2F3F5;
    border-bottom: solid 2px #343D55;
    display: flex;
    color: #07153E;
    @include mq(md,max){
      padding: 0 20px 0 20px;
      min-height: 0;
      border-bottom: solid 1px #343D55;
    }
    &__contents{
      color: $c-black;
      padding: 40px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include mq(md,max){
        padding: 20px 0;
        justify-content: flex-start;
      }
    }
    &__step{
      flex-shrink: 0;
      margin-right: 110px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      font-weight: 100;
      letter-spacing: 0.3em;
      font-size: 14px;
      line-height: 1;
      @include mq(md,max){
        margin-right: 20px;
        padding: 20px 0;
        justify-content: flex-start;
        font-size: 10px;
      }
      &:before{
        content: "";
        border-top: solid 10px #343D55;
        border-right: solid 10px transparent;
        border-left: solid 10px transparent;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
      }
      &:after{
        counter-increment: flowNumber 1;
        content: counter(flowNumber,decimal-leading-zero) "";
        font-size: 40px;
        letter-spacing: 0em;
        margin: 10px 0.1em 0 0;
        font-weight: 400;
        font-family: 'Hiragino Kaku Gothic ProN';
        @include mq(md,max){
          font-size: 26px;
        }
      }
    }
    // $flowColors: (
    //   #FEF2F3: #EB050E,
    //   #FBF3F3: #A91A24,
    //   #F7F4F5: #632837,
    //   #F4F5F6: #343D55,
    // );
    $flowColors: (
      #FEF2F3: #EB050E,
      #FEF1F2: #C8040B,
      #FDF1F2: #A00308,
      #FDF2F3: #780206,
      #FBF4F5: #500104,
      #FAF5F6: #3D0103,
      #F9F6F6: #280102,
      #F8F6F6: #140101,
    );

    @each $bg, $color in $flowColors {
      $i: index($flowColors, $bg $color);
      &:nth-child(#{$i}){
        background-color: $bg;
        color: $color;
        border-color: $color;
        .flow-item__step{
          &:before{
            border-top: solid 10px $color;
          }
        }
      }
    }
    &:last-child{
      border-bottom: 0;
      .flow-item__step{
        &:before{
          display: none;
        }
      }
    }
    &__heading{
      font-size: 26px;
      font-weight: 100;
      line-height: 1.4;
      @include mq(md,max){
        font-size: 18px;
        margin-top: calc(((20px * 1.4) - 20px) / 2 * -1);
      }
    }
    &__text{
      font-size: 16px;
      margin: 10px 0 0;
      @include mq(md,max){
        font-size: 14px;
      }
    }
  }
}
